import React, { Fragment, useEffect, useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorObject, FundingWallet, ProfileTypes } from '../../../types';
import Actions from '../../NewCampaign/Actions';
import { resetCampaign, updateCampaign } from '../../../store/actions/campaign';
import useStoreCampaignSelector from '../../../hooks/useStoreCampaignSelector';
import { showErrorAlert } from '../../../store/actions/alerts';
import { ActionsProps } from '../../NewCampaign/StepsContent';
import { useHistory } from 'react-router-dom';
import { APPROVED, BSC, COIIN, RAIINMAKER, SOCIAL_CHANNEL } from '../../../helpers/constants';
import './campaignSetupForm.scss';
import ContentSteps from './ContentSteps';
import WalkthroughSteps from '../../../componentsv2/Walkthrough/WalkthroughSteps';
import { walkthroughAction } from '../../../store/actions/profile';
import { ApiClient } from '../../../services/apiClient';

const CampaignSetupForm: React.FC<{ company: string } & ActionsProps> = ({
  company,
  activeStep,
  handleBack,
  handleNext,
  firstStep,
  finalStep,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { profile } = useSelector((state: { profile: ProfileTypes }) => state);
  const { walkthrough } = useSelector((state: { walkthrough: { status: boolean } }) => state);
  const campaign = useStoreCampaignSelector();
  const [campaignType, setCampaignType] = useState(campaign.config.campaignType);
  const [socialMediaType, setSocialMediaType] = useState(campaign.config.socialMediaType);
  const [budgetType, setBudgetType] = useState(campaign.config.budgetType);
  const [coiinBudget, setCoiinBudget] = useState(campaign.config.coiinBudget);
  const [isGlobal, setIsGlobal] = useState(campaign.config.isGlobal);
  const [errors, setErrors] = useState<ErrorObject>({});
  const [fundingWallet, setFundingWallet] = useState<FundingWallet[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [steps, setSteps] = useState<number>(1);
  const [walkthroughStep, setWalkthroughStep] = useState<number>(0);
  const [currencyValue, setCurrencyValue] = React.useState<string>(campaign.config.cryptoSymbol);

  //   const totalSteps = stepsList.length - 1;

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const data = await ApiClient.getFundingWallet();
      setFundingWallet(data);
      const defalutCurrency = data.find((x: FundingWallet) => x.type.includes(COIIN) && x.network.includes(BSC));
      setCurrencyValue(currencyValue || `${defalutCurrency?.type}-${defalutCurrency?.network}`);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  const handleSocialMediaType = (type: SOCIAL_CHANNEL[]) => {
    setSocialMediaType(type);
  };

  const handleCampaignType = (type: string) => {
    setCampaignType(type);
  };

  const handleBudgetType = (type: string) => {
    setBudgetType(type);
  };

  const coiinOptions = !isLoading && fundingWallet ? fundingWallet.map((item) => `${item.type}-${item.network}`) : [];

  const handleCoiinBudgetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (parseFloat(event.target.value) < 0) return;
    if (fundingWallet) {
      const token = fundingWallet.find((item) => `${item.type}-${item.network}` === currencyValue);
      if (token) {
        setCoiinBudget(
          parseFloat(event.target.value) > parseFloat(token.balance) ? token.balance.toString() : event.target.value,
        );
        updateErrors('coiinBudget', event.target.value);
      }
    }
  };

  const next = () => {
    if (steps === 3) {
      const walletFunds = parseFloat(
        fundingWallet.find((item) => `${item.type}-${item.network}` === currencyValue)?.balance || '0',
      );
      if (!currencyValue) return updateErrors('currencyValue', currencyValue);
      if (!coiinBudget || parseFloat(coiinBudget) <= 0) return dispatch(showErrorAlert('Please add campaign budget'));
      if (!campaign.id && parseFloat(coiinBudget) > walletFunds)
        return dispatch(showErrorAlert(`Campaign budget cannot be more than funds in your wallet, ${walletFunds}`));
    }
    if (validateInputs()) {
      const augmentedCampaign = {
        ...campaign,
        config: {
          ...campaign.config,
          coiinBudget,
          campaignType,
          socialMediaType,
          budgetType,
          cryptoSymbol: currencyValue,
          isGlobal,
          ...(budgetType === 'raffle' && {
            rafflePrizeName: campaign.config.rafflePrizeName,
            rafflePrizeAffiliateLink: campaign.config.rafflePrizeAffiliateLink,
            raffleImage: campaign.config.raffleImage,
          }),
        },
      };
      dispatch(updateCampaign(augmentedCampaign));
      if (steps >= 3) handleNext();
      else {
        setSteps(steps + 1);
      }
    }
  };

  const back = () => {
    if (steps > 3) handleBack();
    else {
      setSteps(steps - 1);
    }
  };

  const validateInputs = (): boolean => {
    let validated = true;
    if (!socialMediaType || socialMediaType.length === 0) {
      dispatch(showErrorAlert('Please select atleast one social channel'));
      return (validated = false);
    }
    if (!campaignType && steps >= 2) {
      dispatch(showErrorAlert('Please select type of campaign'));
      return (validated = false);
    }
    if (!budgetType && steps > 3) {
      dispatch(showErrorAlert('Please select budget type of your campaign campaign'));
      return (validated = false);
    }
    if (budgetType == 'crypto') {
      if (!currencyValue && steps >= 3) {
        setErrors((prev) => ({ ...prev, currencyValue: true }));
        return (validated = false);
      }
    } else if (budgetType === 'raffle') {
      if (!campaign.config.rafflePrizeName) {
        dispatch(showErrorAlert('Please add campaign budget'));
        setErrors((prev) => ({ ...prev, rafflePrizeName: true }));
        return (validated = false);
      }
      if (!campaign.config.rafflePrizeAffiliateLink) {
        setErrors((prev) => ({ ...prev, rafflePrizeLink: true }));
        return (validated = false);
      }
    }
    return validated;
  };

  const updateErrors = (name: string, data: string | string[] | null) => {
    const key = name;
    const value = data;
    const newErrors = { ...errors };
    if (!value || value.length === 0) {
      newErrors[key] = true;
    } else {
      newErrors[key] = false;
    }
    setErrors(newErrors);
  };

  const handleSelectToken = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrencyValue(event.target.value);
    setCoiinBudget('');
  };

  const handleIsGlobal = () => {
    setIsGlobal(true);
  };

  const handleWalkthroughStep = (step: number) => {
    setWalkthroughStep(step);
    if (profile.email && walkthroughStep > 3) {
      dispatch(walkthroughAction(true));
    }
  };

  if (isLoading) {
    return (
      <Box className="setupFormMessage">
        <CircularProgress size={30} color="primary" className="mt-3" />
      </Box>
    );
  }

  if (!fundingWallet?.length) {
    return (
      <Box className="setupFormMessage">
        <p>
          No cryptocurrency found - Please purchase Crypto{' '}
          <span
            onClick={() => {
              dispatch(resetCampaign());
              history.push('/dashboard/paymentsAccount', true);
            }}
          >
            here
          </span>
        </p>
      </Box>
    );
  }

  if (profile.verifyStatus !== APPROVED && profile.company !== RAIINMAKER) {
    return (
      <Box className="setupFormMessage">
        <p>
          Before creating a new campaign, please verify your KYC{' '}
          <span
            onClick={() => {
              dispatch(resetCampaign());
              history.push('/dashboard/profile');
            }}
          >
            here
          </span>
        </p>
      </Box>
    );
  }

  return (
    <Fragment>
      {!walkthrough.status ? (
        <WalkthroughSteps callback={handleWalkthroughStep} />
      ) : (
        <Box className="campaignSetupFormWrapper">
          <ContentSteps
            isGlobal={isGlobal}
            campaignType={campaignType}
            handleCampaignType={handleCampaignType}
            handleSocialMediaType={handleSocialMediaType}
            socialMediaType={socialMediaType}
            steps={steps}
            budgetType={budgetType}
            campaign={campaign}
            coiinBudget={coiinBudget}
            coiinOptions={coiinOptions}
            company={company}
            errors={errors}
            handleBudgetType={handleBudgetType}
            handleCoiinBudgetChange={handleCoiinBudgetChange}
            handleSelectToken={handleSelectToken}
            handleIsGlobal={handleIsGlobal}
            currencyValue={currencyValue}
            fundingWallet={fundingWallet}
          />

          <Actions
            activeStep={activeStep}
            firstStep={firstStep}
            finalStep={finalStep}
            handleBack={back}
            handleNext={next}
            subStep={steps}
          />
        </Box>
      )}
    </Fragment>
  );
};

export default CampaignSetupForm;
