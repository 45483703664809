import { useSelector } from 'react-redux';
import { RootStoreState } from '../store/reducers';
import { ProfileTypes } from '../types';

const useStoreProfileSelector = (): ProfileTypes => {
  const selectUser = (state: RootStoreState) => state.profile;
  return useSelector(selectUser);
};

export default useStoreProfileSelector;
