import React from 'react';
import './styles.scss';
import { formatNumber } from '../../../../helpers/utils';

interface Props {
  name: string;
  value: number;
  showTotal?: boolean;
}

const MetricsCard: React.FC<Props> = ({ name, value, showTotal }) => {
  return (
    <div className="metricsCard">
      <div>
        <span className="name">
          {showTotal ? 'Total' : ''} {name}
        </span>
      </div>
      <span className={`value ${name}`}>{formatNumber(value)}</span>
    </div>
  );
};

export default MetricsCard;
