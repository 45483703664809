import React, { ChangeEvent, FC } from 'react';
import SelectCurrency from '../../../componentsv2/SelectCurrency';
import FundingWallet from '../../../sections/CryptoWallet';
import { CampaignState, ErrorObject, FundingWallet as FundingWalletType } from '../../../types';
import CustomInput from '../../CustomInput';

interface CampaignBudget {
  budgetType: string;
  company: string;
  handleBudgetType: (type: string) => void;
  handleCoiinBudgetChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectToken: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  coiinOptions: string[];
  errors: ErrorObject;
  campaign: CampaignState;
  coiinBudget: string;
  isGlobal: boolean;
  handleIsGlobal: () => void;
  currencyValue: string | null;
  fundingWallet: FundingWalletType[];
}

const CampaignBudget: FC<CampaignBudget> = ({
  handleCoiinBudgetChange,
  handleSelectToken,
  coiinOptions,
  errors,
  campaign,
  coiinBudget,
  currencyValue,
  fundingWallet,
}: CampaignBudget) => {
  return (
    <div className="campaignBudgets">
      <h1 className="title">
        <span>3/3</span> What Is Your campaign Budget?
      </h1>
      {/* <CampaignBudgetTypeInput budgetType={budgetType} company={company} handleChange={handleBudgetType} /> */}
      <div className="selectFieldsWrapper">
        <div className="selectField">
          <SelectCurrency
            label="Currency*"
            value={currencyValue}
            options={coiinOptions}
            handleOnChange={(event: ChangeEvent<HTMLSelectElement>) => {
              handleSelectToken(event);
            }}
          />

          <div className="selectFieldOutline">
            <CustomInput
              required={true}
              value={coiinBudget}
              onChange={handleCoiinBudgetChange}
              label="Amount"
              type="number"
              error={errors['coiinBudget']}
              disabled={Boolean(campaign.id)}
            />
          </div>

          {/* <div className="checkboxWrapper">
            <FormControlLabel
              control={
                <Checkbox checked={isGlobal} className="checkbox" name="Brand Agreement" onChange={handleIsGlobal} />
              }
              label="Is Global Campaign"
            />
          </div> */}
        </div>

        <div className="fundingWallet">
          <FundingWallet isCreateCampaign={true} activeTab={'FundingWallet'} fundingWalletData={fundingWallet} />
        </div>
      </div>
    </div>
  );
};

export default CampaignBudget;
