export interface Metrics {
  [key: string]: number;
  likes: number;
  views: number;
  shares: number;
  comments: number;
  downloads: number;
}

export interface Creator {
  name: string;
  username: string;
  metrics: Metrics;
}

export interface PointValues {
  [key: string]: number;
  likes: number;
  views: number;
  shares: number;
  comments: number;
  downloads: number;
}

export const pastCampaigns: {
  id: string;
  name: string;
  budget: number;
  currency: string;
  beginDate: string;
  endDate: string;
  metrics: Metrics;
  creators: Creator[];
  pointValues: PointValues;
}[] = [
  {
    id: '1',
    name: 'Campaign 1',
    budget: 20000,
    currency: 'COIIN-BSC',
    beginDate: '20-08-2023',
    endDate: '10-09-2023',
    pointValues: {
      likes: 1,
      views: 2,
      shares: 6,
      comments: 3,
      downloads: 9,
    },
    metrics: {
      likes: 20000,
      views: 50000,
      shares: 12000,
      comments: 21000,
      downloads: 13000,
    },
    creators: [
      {
        name: 'Murad Malik',
        username: 'muradmalik7',
        metrics: {
          likes: 3000,
          views: 8000,
          shares: 1000,
          comments: 2500,
          downloads: 1000,
        },
      },
      {
        name: 'Ray Parker',
        username: 'rayparker7',
        metrics: {
          likes: 5000,
          views: 12000,
          shares: 3000,
          comments: 2500,
          downloads: 3000,
        },
      },
      {
        name: 'Aqueel Abubakar',
        username: 'aqeelvp7',
        metrics: {
          likes: 4000,
          views: 13000,
          shares: 1500,
          comments: 6000,
          downloads: 2000,
        },
      },
      {
        name: 'Jean Pierre',
        username: 'jeanpierre7',
        metrics: {
          likes: 2000,
          views: 9500,
          shares: 3500,
          comments: 7000,
          downloads: 4500,
        },
      },
      {
        name: 'Kris Seraphine',
        username: 'kris.seraphine7',
        metrics: {
          likes: 6000,
          views: 7500,
          shares: 3000,
          comments: 4000,
          downloads: 2500,
        },
      },
    ],
  },
  {
    id: '2',
    name: 'Campaign 2',
    budget: 50000,
    currency: 'COIIN-BSC',
    beginDate: '05-08-2023',
    endDate: '28-08-2023',
    metrics: {
      likes: 70000,
      views: 100000,
      shares: 80000,
      comments: 41000,
      downloads: 65000,
    },
    pointValues: {
      likes: 1,
      views: 2,
      shares: 6,
      comments: 3,
      downloads: 9,
    },
    creators: [
      {
        name: 'Murad Malik',
        username: 'muradmalik7',
        metrics: {
          likes: 30000,
          views: 40000,
          shares: 20000,
          comments: 21000,
          downloads: 15000,
        },
      },
      {
        name: 'Ray Parker',
        username: 'rayparker7',
        metrics: {
          likes: 5000,
          views: 15000,
          shares: 10000,
          comments: 5000,
          downloads: 10000,
        },
      },
      {
        name: 'Aqueel Abubakar',
        username: 'aqeelvp7',
        metrics: {
          likes: 10000,
          views: 25000,
          shares: 5000,
          comments: 5000,
          downloads: 20000,
        },
      },
      {
        name: 'Jean Pierre',
        username: 'jeanpierre7',
        metrics: {
          likes: 8000,
          views: 10000,
          shares: 30000,
          comments: 5000,
          downloads: 5000,
        },
      },
      {
        name: 'Kris Seraphine',
        username: 'kris.seraphine7',
        metrics: {
          likes: 17000,
          views: 10000,
          shares: 15000,
          comments: 5000,
          downloads: 15000,
        },
      },
    ],
  },
];
