import React, { FC } from 'react';
import { ChannelTemplateStructure } from '../../../types';
import CampaignTemplate from './CampaignTemplate';
import twitterIcon from '../../../assets/svg/socialIcons/TwitterLogo.svg';
import instagramIcon from '../../../assets/svg/socialIcons/InstagramLogo.svg';
import tiktokIcon from '../../../assets/svg/socialIcons/TikTokLogo.svg';
import facebookIcon from '../../../assets/svg/socialIcons/FBLogo.svg';
import twitterPhone from '../../../assets/png/templateImages/twitter.png';
import instagramPhone from '../../../assets/png/templateImages/instagram.png';
import tiktokPhone from '../../../assets/png/templateImages/tiktok.png';
import facebookPhone from '../../../assets/png/templateImages/facebook.png';
import { SOCIAL_CHANNEL } from '../../../helpers/constants';

interface TemplateStepsIProps {
  channelTemplates: ChannelTemplateStructure;
  addPost: (channel: SOCIAL_CHANNEL) => void;
  handlePostChange: (params: { channel: SOCIAL_CHANNEL; index: number; data: string }) => void;
  removePost: (channel: SOCIAL_CHANNEL, index: number) => void;
  platfrom: SOCIAL_CHANNEL;
}

const TemplateSteps: FC<TemplateStepsIProps> = ({
  channelTemplates,
  addPost,
  handlePostChange,
  removePost,
  platfrom,
}: TemplateStepsIProps) => {
  switch (platfrom) {
    case SOCIAL_CHANNEL.TWITTER:
      return (
        <CampaignTemplate
          channelTemplates={channelTemplates.TWITTER}
          addPost={addPost}
          handlePostChange={handlePostChange}
          removePost={removePost}
          phoneImage={twitterPhone}
          socialIcon={twitterIcon}
          platform={SOCIAL_CHANNEL.TWITTER}
        />
      );
    case SOCIAL_CHANNEL.INSTAGRAM:
      return (
        <CampaignTemplate
          channelTemplates={channelTemplates.INSTAGRAM}
          addPost={addPost}
          handlePostChange={handlePostChange}
          removePost={removePost}
          phoneImage={instagramPhone}
          socialIcon={instagramIcon}
          platform={SOCIAL_CHANNEL.INSTAGRAM}
        />
      );
    case SOCIAL_CHANNEL.FACEBOOK:
      return (
        <CampaignTemplate
          channelTemplates={channelTemplates.FACEBOOK}
          addPost={addPost}
          handlePostChange={handlePostChange}
          removePost={removePost}
          phoneImage={facebookPhone}
          socialIcon={facebookIcon}
          platform={SOCIAL_CHANNEL.FACEBOOK}
        />
      );
    case SOCIAL_CHANNEL.TIKTOK:
      return (
        <CampaignTemplate
          channelTemplates={channelTemplates.TIKTOK}
          addPost={addPost}
          handlePostChange={handlePostChange}
          removePost={removePost}
          phoneImage={tiktokPhone}
          socialIcon={tiktokIcon}
          platform={SOCIAL_CHANNEL.TIKTOK}
        />
      );
    default:
      return (
        <CampaignTemplate
          channelTemplates={channelTemplates.TIKTOK}
          addPost={addPost}
          handlePostChange={handlePostChange}
          removePost={removePost}
          phoneImage={tiktokPhone}
          socialIcon={tiktokIcon}
          platform={SOCIAL_CHANNEL.TIKTOK}
        />
      );
  }
};

export default TemplateSteps;
