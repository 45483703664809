import React, { FC } from 'react';
import './styles.scss';
import { TTCMActionsProps } from './Content';
import Actions from '../../../components/NewCampaign/Actions';
import CustomButton from '../../../components/CustomButton';
import InstructionImage from '../../../assets/png/tcmInstruction.png';

const CampaignSelectionForm: FC<TTCMActionsProps> = ({ activeStep, firstStep, finalStep, handleBack, handleNext }) => {
  return (
    <div className="campaignSelectionContainer">
      <h1 className="title">
        Select which Tiktok Creator Marketplace Campaign You would like to payout via raiinmaker
      </h1>

      <div className="campaignsWrapper">
        <div className="campaignList">
          <h3>Campaign List</h3>
        </div>

        <CustomButton className="refreshButton">Refresh</CustomButton>
      </div>

      <div className="instructionsWrapper">
        <div className="instruction">
          <h2>1. Ensure that the campaign payout method is set to “OFFLINE”</h2>
          <img src={InstructionImage} alt="tcm instructions" />
        </div>
        <div className="instruction">
          <h2>
            2.Make sure to notify all Campaign Creators that the campaign will be paid via raiinmaker&apos;s Algorithm
          </h2>
        </div>
      </div>

      <Actions
        activeStep={activeStep}
        firstStep={firstStep}
        finalStep={finalStep}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    </div>
  );
};

export default CampaignSelectionForm;
