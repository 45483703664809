import React from 'react';
import './styles.scss';
import { TextField } from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import { useDispatch } from 'react-redux';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Fade } from 'react-awesome-reveal';
import { Autocomplete } from '@material-ui/lab';
import { useState } from 'react';
import { ErrorObject } from '../../../types';
import { TTCMActionsProps } from './Content';
import CustomInput from '../../../components/CustomInput';
import Actions from '../../../components/NewCampaign/Actions';
import useStoreTTCMCampaignSelector from '../../../hooks/useStoreTTCMCampaignSelector';
import { updateTTCMCampaign } from '../../../store/actions/ttcmCampaign';
import { MAX_POST_LENGTH, MAX_TAGS_LENGTH } from '../../../helpers/constants';

const InfoForm: React.FC<TTCMActionsProps> = ({ activeStep, handleBack, handleNext, firstStep, finalStep }) => {
  const dispatch = useDispatch();
  const campaign = useStoreTTCMCampaignSelector();
  const [name, setName] = useState(campaign.name);
  const [target, setTarget] = useState(campaign.target);
  const [targetVideo, setTargetVideo] = useState(campaign.target);
  const [keywords, setKeywords] = useState(campaign.keywords);
  const [description, setDescription] = useState(campaign.description);
  const [instructions, setInstructions] = useState(campaign.instructions);
  const [tagline, setTagline] = useState(campaign.tagline);
  const [tags, setTags] = useState<string[]>(campaign.tags);

  const [beginDate, setBeginDate] = useState(
    campaign.beginDate ||
      new Date().toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }),
  );
  const [endDate, setEndDate] = useState(
    campaign.endDate || new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString(),
  );
  const [errors, setErrors] = useState<ErrorObject>({});

  const handleBeginDateChange = (date: MaterialUiPickersDate) => {
    const dateIsoString = date?.toISOString();
    if (endDate && dateIsoString && new Date(endDate).getTime() < new Date(dateIsoString).getTime()) {
      updateErrors('beginDate', '');
    }
    if (dateIsoString) {
      const date = new Date(dateIsoString);
      const nextMonth = new Date(date.setMonth(date.getMonth() + 1));
      setBeginDate(dateIsoString);
      setEndDate(nextMonth.toISOString());
    }
    updateErrors('beginDate', dateIsoString);
  };

  const handleEndDateChange = (date: MaterialUiPickersDate) => {
    const dateIsoString = date?.toISOString();
    if (beginDate && dateIsoString && new Date(beginDate).getTime() >= new Date(dateIsoString).getTime()) {
      updateErrors('endDate', '');
    }
    if (dateIsoString) {
      setEndDate(dateIsoString);
    }
    updateErrors('endDate', dateIsoString);
  };

  const updateErrors = (key: string, data: string | string[] | undefined) => {
    const value = data;
    const newErrors = { ...errors };
    if (!value || value.length === 0) {
      newErrors[key] = true;
    } else if (value) {
      newErrors[key] = false;
    } else {
      if (key === 'tags') {
        if (data.length > MAX_POST_LENGTH) {
          newErrors[key] = true;
        } else {
          newErrors[key] = false;
        }
      }
    }
    setErrors(newErrors);
  };

  const next = () => {
    if (validateInputs()) {
      const augmentedCampaign = {
        ...campaign,
        name,
        description,
        instructions,
        target,
        targetVideo,
        keywords,
        beginDate,
        endDate,
        tags,
        tagline,
      };
      dispatch(updateTTCMCampaign(augmentedCampaign));
      handleNext();
    }
  };

  // const getTagValue = () => {
  //   const values = tags.split(',');
  //   return values.map((item) => {
  //     item = item.trim();
  //     return item ? (item.includes('#') ? `${item}` : `#${item}`) : '';
  //   });
  // };

  const validateInputs = (): boolean => {
    let validated = true;
    if (!name) {
      setErrors((prev) => ({ ...prev, name: true }));
      return (validated = false);
    }
    if (!tagline) {
      setErrors((prev) => ({ ...prev, tagline: true }));
      return (validated = false);
    }
    if (!keywords || !keywords.length) {
      setErrors((prev) => ({ ...prev, keywords: true }));
      return (validated = false);
    }
    if (!tags || !tags.length) {
      setErrors((prev) => ({ ...prev, tags: true }));
      return (validated = false);
    }
    if (!tags) {
      setErrors((prev) => ({ ...prev, tags: true }));
      return (validated = false);
    }
    if (tags.length > MAX_TAGS_LENGTH) {
      setErrors((prev) => ({ ...prev, tags: true }));
      return (validated = false);
    }
    if (!description) {
      setErrors((prev) => ({ ...prev, description: true }));
      return (validated = false);
    }
    if (!instructions) {
      setErrors((prev) => ({ ...prev, instructions: true }));
      return (validated = false);
    }
    if (!target) {
      setErrors((prev) => ({ ...prev, target: true }));
      return (validated = false);
    }
    if (!targetVideo) {
      setErrors((prev) => ({ ...prev, target: true }));
      return (validated = false);
    }
    if (!beginDate) {
      setErrors((prev) => ({ ...prev, beginDate: true }));
      return (validated = false);
    }
    if (!endDate) {
      setErrors((prev) => ({ ...prev, endDate: true }));
      return (validated = false);
    }
    return validated;
  };

  return (
    <Fade>
      <div className="infoContainer">
        <h1 className="title">Enter TikTok Creator Marketplace info for Raiinmaker</h1>
        <div className="campaignInitializeFormOutline">
          <div className="inputFieldLg">
            <CustomInput
              required={true}
              value={name}
              placeholder="name"
              label="Name of Campaign"
              error={errors['name']}
              onChange={(e) => {
                setName(e.target.value);
                updateErrors('name', e.target.value);
              }}
            />
          </div>
          <div className="inputField">
            <DateTimePicker
              required={true}
              value={beginDate || null}
              inputVariant="outlined"
              variant="dialog"
              fullWidth
              label="Campaign Start Date"
              showTodayButton
              className="customInput"
              error={errors['beginDate']}
              onChange={handleBeginDateChange}
            />
          </div>
          <div className="inputField">
            <DateTimePicker
              required={true}
              inputVariant="outlined"
              fullWidth
              value={endDate || null}
              disablePast
              label="Campaign End Date"
              showTodayButton
              error={errors['endDate']}
              onChange={handleEndDateChange}
              className="customInput"
            />
          </div>
          <div className="inputField">
            <CustomInput
              required={true}
              label="Campaign Tagline"
              placeholder="Tagline"
              value={tagline}
              error={errors['tagline']}
              onChange={(e) => {
                setTagline(e.target.value);
                updateErrors('tagline', e.target.value);
              }}
            />
          </div>
          <div className="inputField">
            <Autocomplete
              className="autoComplete"
              id="keywords"
              freeSolo={true}
              multiple={true}
              options={keywords}
              value={keywords}
              getOptionLabel={(option) => option}
              defaultValue={keywords}
              onChange={(event, newValue) => {
                setKeywords(newValue);
                updateErrors('keywords', newValue);
              }}
              onInputChange={(event, newInputValue) => {
                const options = newInputValue.split(',');
                if (options.length > 1) {
                  const list = keywords
                    .concat(options)
                    .map((x) => x.trim())
                    .filter((x) => x);
                  setKeywords(Array.from(new Set(list)));
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="textField"
                  required={true}
                  error={errors['keywords']}
                  {...params}
                  variant="outlined"
                  label="Keywords"
                  placeholder="Add keywords for campaign and press enter"
                />
              )}
            />
          </div>

          <div className="autoCompleteFieldLg">
            <Autocomplete
              className="autoComplete"
              id="tags"
              freeSolo={true}
              multiple={true}
              options={tags}
              value={tags}
              getOptionLabel={(option) => option}
              defaultValue={tags}
              onChange={(event: any, newValue) => {
                const values = [...newValue];
                const lastValue = values[values.length - 1];
                if (!lastValue?.includes('#') && event.key === 'Enter') {
                  values[values.length - 1] = '#'.concat(newValue[values.length - 1]);
                }
                setTags(values);
              }}
              renderInput={(params) => (
                <TextField
                  className="textField"
                  required={true}
                  error={errors['tags']}
                  {...params}
                  variant="outlined"
                  label="# (comma seperated values)*"
                  placeholder="Add hashtags for campaign"
                />
              )}
            />
          </div>

          <div className="inputFieldLg">
            <CustomInput
              required={true}
              label="Description"
              multiline
              value={description}
              rows={3}
              error={errors['description']}
              onChange={(e) => {
                setDescription(e.target.value);
                updateErrors('description', e.target.value);
              }}
            />
          </div>
          <div className="inputFieldLg">
            <CustomInput
              required={true}
              label="Campaign Instructions"
              multiline
              value={instructions}
              rows={3}
              error={errors['instructions']}
              onChange={(e) => {
                setInstructions(e.target.value);
                updateErrors('instructions', e.target.value);
              }}
            />
          </div>

          <div className="inputField">
            <CustomInput
              required={true}
              value={target}
              placeholder="Landing Page URL: must start with http or https"
              label="Landing Page URL"
              error={errors['target']}
              onChange={(e) => {
                setTarget(e.target.value);
                updateErrors('target', e.target.value);
              }}
            />
          </div>
          <div className="inputField">
            <CustomInput
              required={true}
              value={targetVideo}
              placeholder="URL must start with http or https"
              label="Landing Page Video URL"
              error={errors['targetVideo']}
              onChange={(e) => {
                setTargetVideo(e.target.value);
                updateErrors('targetVideo', e.target.value);
              }}
            />
          </div>
        </div>
        <div className="w-full">
          <Actions
            activeStep={activeStep}
            firstStep={firstStep}
            finalStep={finalStep}
            handleBack={handleBack}
            handleNext={next}
          />
        </div>
      </div>
    </Fade>
  );
};

export default InfoForm;
