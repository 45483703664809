import React, { FC } from 'react';
import { ChannelMediaObject, ChannelMediaStructure, ChannelMediaTypes, FileObject } from '../../../types';
import CampaignMedia from './CampaignMedia';
import ChannelMedia from './ChannelMedia';
import instagramPhone from '../../../assets/png/medias/instagram.png';
import tiktokPhone from '../../../assets/png/medias/tiktok.png';
import twitterPhone from '../../../assets/png/medias/twitter.png';
import facebookPhone from '../../../assets/png/medias/facebook.png';
import facebookPhone2 from '../../../assets/png/medias/facebook3x4.png';
import twitterPhone2 from '../../../assets/png/medias/twitter3x4.png';
import instagramPhone2 from '../../../assets/png/medias/instagram3x4.png';
import instagramHz from '../../../assets/png/medias/instagramHz.png';
import facebookHz from '../../../assets/png/medias/facebookHz.png';
import twitterHz from '../../../assets/png/medias/twitterHz.png';
import { useSelector } from 'react-redux';
import { SOCIAL_CHANNEL, CAMPAIGN, AI_GENERATIVE } from '../../../helpers/constants';
import AIModels from './AIModels';

interface MediaStepsIProps {
  campaignImage: FileObject;
  socialMediaType: string[];
  onCampaignImageSuccess: (data: FileObject) => void;
  imageToImage: boolean;
  setImageToImage: (val: boolean) => void;
  selectedModels: string[];
  updateModelData: (data: string) => void;
  onError: (msg: string) => void;
  channelMedia: ChannelMediaStructure;
  handleChannelMedia: (channel: SOCIAL_CHANNEL, ratio: string, data: FileObject, slug: string) => void;
  removeChannelMedia: (index: number, data: ChannelMediaObject, ratio: string) => void;
  platform: SOCIAL_CHANNEL | typeof CAMPAIGN | typeof AI_GENERATIVE;
}

const MediaSteps: FC<MediaStepsIProps> = ({
  campaignImage,
  onCampaignImageSuccess,
  onError,
  channelMedia,
  handleChannelMedia,
  removeChannelMedia,
  platform,
  updateModelData,
  imageToImage,
  setImageToImage,
  selectedModels,
}) => {
  const channelMediaList = useSelector((state: { channelMedia: ChannelMediaTypes }) => state.channelMedia);

  switch (platform) {
    case CAMPAIGN:
      return (
        <CampaignMedia
          title="Upload your campaign image cover for the raiinmaker app"
          campaignImage={campaignImage}
          onCampaignImageSuccess={onCampaignImageSuccess}
          onError={onError}
        />
      );
    case SOCIAL_CHANNEL.TWITTER:
      return (
        <ChannelMedia
          channelMedia={channelMedia.TWITTER}
          onError={onError}
          channelName={SOCIAL_CHANNEL.TWITTER}
          socialPlatFormImage={twitterPhone}
          secondMobileImage={twitterPhone2}
          horizontalVideo={twitterHz}
          handleChannelMedia={handleChannelMedia}
          firstMedia={channelMediaList.TWITTER.first}
          secondMedia={channelMediaList.TWITTER.second}
          thirdMedia={channelMediaList.TWITTER.third}
          removeChannelMedia={removeChannelMedia}
          title="Upload media you want users to view & share on Twitter"
        />
      );
    case SOCIAL_CHANNEL.INSTAGRAM:
      return (
        <ChannelMedia
          channelMedia={channelMedia.INSTAGRAM}
          onError={onError}
          channelName={SOCIAL_CHANNEL.INSTAGRAM}
          socialPlatFormImage={instagramPhone}
          secondMobileImage={instagramPhone2}
          horizontalVideo={instagramHz}
          handleChannelMedia={handleChannelMedia}
          firstMedia={channelMediaList.INSTAGRAM.first}
          secondMedia={channelMediaList.INSTAGRAM.second}
          thirdMedia={channelMediaList.INSTAGRAM.third}
          removeChannelMedia={removeChannelMedia}
          title="Upload media you want users to view & share on Instagram"
        />
      );
    case SOCIAL_CHANNEL.FACEBOOK:
      return (
        <ChannelMedia
          channelMedia={channelMedia.FACEBOOK}
          onError={onError}
          channelName={SOCIAL_CHANNEL.FACEBOOK}
          socialPlatFormImage={facebookPhone}
          secondMobileImage={facebookPhone2}
          horizontalVideo={facebookHz}
          handleChannelMedia={handleChannelMedia}
          firstMedia={channelMediaList.FACEBOOK.first}
          secondMedia={channelMediaList.FACEBOOK.second}
          thirdMedia={channelMediaList.FACEBOOK.third}
          removeChannelMedia={removeChannelMedia}
          title="Upload media you want users to view & share on Facebook"
        />
      );
    case SOCIAL_CHANNEL.TIKTOK:
      return (
        <ChannelMedia
          channelMedia={channelMedia.TIKTOK}
          onError={onError}
          channelName={SOCIAL_CHANNEL.TIKTOK}
          socialPlatFormImage={tiktokPhone}
          handleChannelMedia={handleChannelMedia}
          firstMedia={channelMediaList.TIKTOK.first}
          removeChannelMedia={removeChannelMedia}
          title="Upload media you want users to view & share on Tiktok"
        />
      );
    case AI_GENERATIVE:
      return (
        <AIModels
          imageToImage={imageToImage}
          setImageToImage={setImageToImage}
          updateModelData={updateModelData}
          selectedModels={selectedModels}
        />
      );
    default:
      return (
        <CampaignMedia
          title="Upload your campaign image cover for the raiinmaker app"
          campaignImage={campaignImage}
          onCampaignImageSuccess={onCampaignImageSuccess}
          onError={onError}
        />
      );
  }
};

export default MediaSteps;
