import React from 'react';
import './styles.scss';
import { useDispatch } from 'react-redux';
import { Fade } from 'react-awesome-reveal';
import { useState } from 'react';
import { ErrorObject } from '../../../types';
import { TTCMActionsProps } from './Content';
import CustomInput from '../../../components/CustomInput';
import Actions from '../../../components/NewCampaign/Actions';
import useStoreTTCMCampaignSelector from '../../../hooks/useStoreTTCMCampaignSelector';
import { updateTTCMCampaign } from '../../../store/actions/ttcmCampaign';

const PayoutValuesForm: React.FC<TTCMActionsProps> = ({ activeStep, handleBack, handleNext, firstStep, finalStep }) => {
  const dispatch = useDispatch();
  const campaign = useStoreTTCMCampaignSelector();
  const [likes, setLikes] = useState(campaign.algorithm.pointValues.likes);
  const [views, setViews] = useState(campaign.algorithm.pointValues.views);
  const [shares, setShares] = useState(campaign.algorithm.pointValues.shares);
  const [comments, setComments] = useState(campaign.algorithm.pointValues.comments);
  const [downloads, setDownloads] = useState(campaign.algorithm.pointValues.downloads);

  const [errors, setErrors] = useState<ErrorObject>({});

  const updateErrors = (key: string, data: string | string[] | undefined) => {
    const value = data;
    const newErrors = { ...errors };
    if (!value) {
      newErrors[key] = true;
    }
    setErrors(newErrors);
  };

  const next = () => {
    if (validateInputs()) {
      const augmentedCampaign = {
        ...campaign,
        algorithm: {
          pointValues: { likes, shares, views, comments, downloads },
          tiers: { '1': { threshold: '0', totalCoiins: campaign.budget as string } },
        },
      };
      dispatch(updateTTCMCampaign(augmentedCampaign));
      handleNext();
    }
  };

  // const getTagValue = () => {
  //   const values = tags.split(',');
  //   return values.map((item) => {
  //     item = item.trim();
  //     return item ? (item.includes('#') ? `${item}` : `#${item}`) : '';
  //   });
  // };

  const validateInputs = (): boolean => {
    let validated = true;
    if (!likes) {
      setErrors((prev) => ({ ...prev, name: true }));
      return (validated = false);
    }
    return validated;
  };

  return (
    <Fade>
      <div className="pointValuesContainer">
        <h1 className="title">Values (payout parameters)</h1>
        <div className="campaignInitializeFormOutline">
          <div className="inputFieldLg">
            <CustomInput
              required={true}
              value={likes}
              placeholder="Likes value"
              label="Likes Value"
              error={errors['likes']}
              onChange={(e) => {
                setLikes(e.target.value);
                updateErrors('likes', e.target.value);
              }}
            />
          </div>

          <div className="inputFieldLg">
            <CustomInput
              required={true}
              value={shares}
              placeholder="Shares value"
              label="Shares Value"
              error={errors['shares']}
              onChange={(e) => {
                setShares(e.target.value);
                updateErrors('shares', e.target.value);
              }}
            />
          </div>

          <div className="inputFieldLg">
            <CustomInput
              required={true}
              value={views}
              placeholder="Views value"
              label="Views Value"
              error={errors['views']}
              onChange={(e) => {
                setViews(e.target.value);
                updateErrors('views', e.target.value);
              }}
            />
          </div>

          <div className="inputFieldLg">
            <CustomInput
              required={true}
              value={comments}
              placeholder="Comments value"
              label="Comments Value"
              error={errors['comments']}
              onChange={(e) => {
                setComments(e.target.value);
                updateErrors('comments', e.target.value);
              }}
            />
          </div>

          <div className="inputFieldLg">
            <CustomInput
              required={true}
              value={downloads}
              placeholder="Downloads value"
              label="Downloads Value"
              error={errors['downloads']}
              onChange={(e) => {
                setDownloads(e.target.value);
                updateErrors('downloads', e.target.value);
              }}
            />
          </div>
        </div>
        <div className="w-full">
          <Actions
            activeStep={activeStep}
            firstStep={firstStep}
            finalStep={finalStep}
            handleBack={handleBack}
            handleNext={next}
          />
        </div>
      </div>
    </Fade>
  );
};

export default PayoutValuesForm;
