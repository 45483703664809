import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import useStoreUserSelector from '../hooks/useStoreUserSelector';
import useStoreSettingsSelector from '../hooks/useStoreSettingsSelector';

export const ProtectedRoute: React.FC<RouteProps> = (props) => {
  const { isLoggedIn } = useStoreUserSelector();
  const { ttcm } = useStoreSettingsSelector();
  const renderRoute = () => {
    if (isLoggedIn) {
      if (ttcm) return <Redirect to={{ pathname: '/ttcm/dashboard' }} />;
      return <Route {...props} />;
    } else {
      return <Redirect to={{ pathname: '/login' }} />;
    }
  };

  return <div>{renderRoute()}</div>;
};

export const PublicRoute: React.FC<RouteProps> = (props) => {
  const { isLoggedIn } = useStoreUserSelector();
  const { ttcm } = useStoreSettingsSelector();

  const renderRoute = () => {
    if (!isLoggedIn) {
      return <Route {...props} />;
    } else {
      if (ttcm) return <Redirect to={{ pathname: '/ttcm/dashboard' }} />;
      return <Redirect to={{ pathname: '/dashboard/campaigns' }} />;
    }
  };

  return renderRoute();
};

export const TTCMProtectedRoute: React.FC<RouteProps & { requireTiktokLogin?: boolean }> = (props) => {
  const { isLoggedIn, isTiktokLinked } = useStoreUserSelector();
  const { ttcm } = useStoreSettingsSelector();
  const renderRoute = () => {
    if (isLoggedIn) {
      if (!ttcm) return <Redirect to={{ pathname: '/dashboard/campaigns' }} />;
      if (props.requireTiktokLogin && !isTiktokLinked) return <Redirect to={{ pathname: '/ttcm/login' }} />;
      return <Route {...props} />;
    } else {
      return <Redirect to={{ pathname: '/login' }} />;
    }
  };

  return <div>{renderRoute()}</div>;
};
