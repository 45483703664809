import React, { ChangeEvent, useEffect, useState } from 'react';
import tableStyles from '../../assets/styles/table.module.css';
import CustomButton from '../CustomButton';
import buttonStyle from '../../assets/styles/customButton.module.css';
import { CircularProgress } from '@material-ui/core';
import { useParams, useLocation } from 'react-router-dom';
import headingStyles from '../../assets/styles/heading.module.css';
import buttonStyles from '../../assets/styles/customButton.module.css';
import Pagination from '../Pagination/Pagination';
import { ParticipantTypes, SortType, Campaign } from '../../types';
import { ApiClient } from '../../services/apiClient';
import { useDispatch } from 'react-redux';
import { showErrorAlert } from '../../store/actions/alerts';
import fileDownload from 'js-file-download';
import './campaign.scss';

const CampaignParticipants: React.FC = () => {
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const { state }: { state: { campaign: Campaign } } = useLocation();
  const [participants, setParticipants] = useState<ParticipantTypes[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [participantId, setParticipantId] = useState<string>('');
  const [blacklistLoading, setBlacklistLoading] = useState<boolean>(false);
  const [searchData, setSearchData] = useState<string>('');
  const [filter, setFilter] = useState<string>('');
  const [skip, setSkip] = useState<number>(0);
  const [take] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [sort, setSort] = useState<SortType>('desc');
  const [campaignSymbol, setCampaignSymbol] = useState<string>('');
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);

  useEffect(() => {
    filter ? setSearchLoading(true) : setIsLoading(true);
    ApiClient.getCampaignParticipants({ campaignId: id, filter, skip, take, sort })
      .then((response) => {
        setParticipants(response.participants);
        setTotal(response.count);
        setCampaignSymbol(response.campaignSymbol);
      })
      .catch((error) => dispatch(showErrorAlert((error as Error).message)))
      .finally(() => {
        setIsLoading(false);
        setSearchLoading(false);
      });
  }, [id, filter, skip, reload, sort]);

  // Blacklist a participant
  const blacklistParticipant = (participantId: string) => {
    setParticipantId(participantId);
    setBlacklistLoading(true);
    ApiClient.blacklistParticipant({ participantId })
      .then(() => setReload(true))
      .catch((error) => dispatch(showErrorAlert((error as Error).message)))
      .finally(() => setBlacklistLoading(false));
  };

  // Search field
  const handleSearchField = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchData(e.target.value);
    if (!e.target.value) {
      setFilter('');
    }
  };

  // Search record
  const handleSearchRecord = () => {
    setFilter(searchData);
  };

  // Handle Key Press
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setFilter(searchData);
    }
  };

  // Take paginated value from Pagination component
  const getValue = (skip: number) => {
    setSkip(skip);
  };

  // Handle sort
  const handleSort = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === 'desc') setSort('desc');
    if (e.target.value === 'asc') setSort('asc');
  };

  // Download participants details
  const downloadRecord = () => {
    setDownloadLoading(true);
    ApiClient.downloadParticipantsRecord({ campaignId: id })
      .then((response: any) => {
        fileDownload(response, `${state?.campaign?.name}-participants-list.csv`);
      })
      .catch((error) => dispatch(showErrorAlert((error as Error).message)))
      .finally(() => setDownloadLoading(false));
  };

  return (
    <div>
      <div className="campaignParticipants">
        <h2 className={headingStyles.heading}>Participant List</h2>
        <div className="items">
          <div className="sorting">
            <p>Sort by Participation Score</p>
            <div className="selectWrap">
              <select name="sorting" onChange={handleSort} className="select">
                <option value={'desc'}>DESC</option>
                <option value={'asc'}>ASC</option>
              </select>
            </div>
          </div>
          <CustomButton className={buttonStyles.buttonPrimary} onClick={downloadRecord} loading={downloadLoading}>
            Download
          </CustomButton>
          <input
            type="text"
            name="search"
            value={searchData}
            className="search"
            placeholder="Search by username or email"
            onChange={handleSearchField}
            onKeyPress={handleKeyPress}
          />
          <CustomButton className={buttonStyles.buttonPrimary} onClick={handleSearchRecord} loading={searchLoading}>
            Search
          </CustomButton>
        </div>
      </div>
      {isLoading ? (
        <div className={tableStyles.tableBodyLoading}>
          <CircularProgress />
        </div>
      ) : (
        <div className={tableStyles.tableWrapper}>
          <table className={tableStyles.table}>
            <thead>
              <tr className={tableStyles.tableHeadRow}>
                <th className={tableStyles.tableColumn}>Username</th>
                <th className={tableStyles.tableColumn}>Email</th>
                <th className={tableStyles.tableColumn}>Twitter Username</th>
                <th className={tableStyles.tableColumn}>Total Likes</th>
                <th className={tableStyles.tableColumn}>Total Shares</th>
                <th className={tableStyles.tableColumn}>Total Tags</th>
                <th className={tableStyles.tableColumn}>Total Generations</th>
                <th className={tableStyles.tableColumn}>Post Count</th>
                <th className={tableStyles.tableColumn}>Like Score</th>
                <th className={tableStyles.tableColumn}>Share Score</th>
                <th className={tableStyles.tableColumn}>Click Score</th>
                <th className={`${tableStyles.tableColumn} cursor-pointer`}>Participation Score</th>
                <th className={tableStyles.tableColumn}>{campaignSymbol}</th>
                <th className={tableStyles.tableColumn}>USD</th>
                <th className={tableStyles.tableColumn}>Action</th>
              </tr>
            </thead>
            <tbody>
              {!participants.length && <p className="p-2">No participant found for this campaign.</p>}
              {participants &&
                participants.map((participant: ParticipantTypes) => (
                  <tr className={tableStyles.tableBodyRow} key={participant.id}>
                    <td className={tableStyles.tableColumn}>{participant.username}</td>
                    <td className={tableStyles.tableColumn}>{participant.email}</td>
                    <td className={tableStyles.tableColumn}>{participant.twitterUsername || ''}</td>
                    <td className={tableStyles.tableColumn}>{participant.totalLikes}</td>
                    <td className={tableStyles.tableColumn}>{participant.totalShares}</td>
                    <td className={tableStyles.tableColumn}>{participant?.totalTags || 0}</td>
                    <td className={tableStyles.tableColumn}>{participant?.totalGenerations || 0}</td>
                    <td className={tableStyles.tableColumn}>{participant.selfPostCount}</td>
                    <td className={tableStyles.tableColumn}>{participant.likeScore}</td>
                    <td className={tableStyles.tableColumn}>{participant.shareScore}</td>
                    <td className={tableStyles.tableColumn}>{participant.clickScore}</td>
                    <td className={tableStyles.tableColumn}>{participant.participationScore}</td>
                    <td className={tableStyles.tableColumn}>{participant.participantShare}</td>
                    <td className={tableStyles.tableColumn}>{participant.participantShareUSD}</td>
                    <td className={tableStyles.tableColumn}>
                      {!participant.blacklist && (
                        <CustomButton
                          className={buttonStyle.secondaryButton}
                          onClick={() => blacklistParticipant(participant.id)}
                          loading={blacklistLoading && participantId === participant.id}
                        >
                          Blacklist
                        </CustomButton>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}

      {total > take && <Pagination total={total} skip={skip} take={take} getValue={getValue} />}
    </div>
  );
};

export default CampaignParticipants;
