import { SOCIAL_CHANNEL } from '../helpers/constants';
import {
  Alert,
  AlgorithmSpecs,
  CampaignState,
  ChannelMediaTypes,
  ProfileTypes,
  StoreAlerts,
  StoreSettings,
  TTCMAlgorithmSpecs,
  TTCMCampaignState,
  UserData,
} from '../types.d';

const initialAlgorithmState: AlgorithmSpecs = {
  pointValues: {
    clicks: '1',
    views: '2',
    submissions: '6',
    likes: '3',
    shares: '9',
  },
  tiers: {},
};

const initialTTCMAlgorithmState: TTCMAlgorithmSpecs = {
  pointValues: {
    likes: '1',
    views: '2',
    shares: '6',
    comments: '3',
    downloads: '9',
  },
  tiers: {},
};

const campaignInitState: CampaignState = {
  id: '',
  name: '',
  beginDate: '',
  endDate: '',
  totalParticipationScore: '',
  description: '',
  algorithm: initialAlgorithmState,
  company: '',
  target: '',
  targetVideo: '',
  currency: '',
  campaignImage: { filename: '', file: '', format: '' },
  media: { filename: '', file: '', format: '' },
  tagline: '',
  suggestedPosts: ['', ''],
  suggestedTags: ['#ad'],
  keywords: [],
  requirements: {
    version: '',
    location: [],
    values: [],
    ageRange: {
      '0-17': false,
      '18-25': false,
      '26-40': false,
      '41-55': false,
      '55+': false,
    },
    interests: [],
    email: false,
    socialFollowing: {
      twitter: {
        minFollower: 0,
      },
    },
  },
  modelIds: [],
  config: {
    type: 'crypto',
    numOfSuggestedPosts: '2',
    cryptoSymbol: '',
    numOfTiers: '3',
    initialTotal: '',
    coiinBudget: '0',
    budget: '',
    budgetType: 'crypto',
    campaignType: '',
    socialMediaType: [SOCIAL_CHANNEL.TWITTER],
    agreementChecked: false,
    raffleImage: { filename: '', file: '', format: '' },
    rafflePrizeName: '',
    rafflePrizeAffiliateLink: '',
    success: false,
    channelMedia: {
      TIKTOK: [],
      TWITTER: [],
      INSTAGRAM: [],
      FACEBOOK: [],
    },
    channelTemplates: {
      TIKTOK: [
        { channel: SOCIAL_CHANNEL.TIKTOK, post: '' },
        { channel: SOCIAL_CHANNEL.TIKTOK, post: '' },
      ],
      TWITTER: [
        { channel: SOCIAL_CHANNEL.TWITTER, post: '' },
        { channel: SOCIAL_CHANNEL.TWITTER, post: '' },
      ],
      INSTAGRAM: [
        { channel: SOCIAL_CHANNEL.INSTAGRAM, post: '' },
        { channel: SOCIAL_CHANNEL.INSTAGRAM, post: '' },
      ],
      FACEBOOK: [
        { channel: SOCIAL_CHANNEL.FACEBOOK, post: '' },
        { channel: SOCIAL_CHANNEL.FACEBOOK, post: '' },
      ],
    },
    isGlobal: false,
    showUrl: false,
    aIGenerative: false,
    imageToImage: false,
    premiumOnly: false,
  },
  instructions: '',
  termsAgreed: false,
};

const success: Alert = {
  message: '',
  open: false,
};

const errorAlert: Alert = {
  message: '',
  open: false,
};

const alerts: StoreAlerts = {
  success: success,
  error: errorAlert,
};

const settings: StoreSettings = {
  appLoader: false,
  loadingMessage: '',
  languageCode: '',
  ttcm: false,
};

const user: UserData = {
  tempPass: false,
  id: '',
  role: '',
  company: '',
  email: '',
  isLoggedIn: false,
  resetPass: false,
  idToken: '',
  twoFactorEnabled: false,
  isTiktokLinked: false,
};

const profile: ProfileTypes = {
  name: '',
  company: '',
  email: '',
  enabled: false,
  imageUrl: '',
  orgId: '',
  verifyStatus: '',
};

const coiin = {
  coiinValue: '0',
  loading: false,
  error: {},
};

const channelMedia: ChannelMediaTypes = {
  TWITTER: {
    first: [],
    second: [],
    third: [],
  },
  INSTAGRAM: {
    first: [],
    second: [],
    third: [],
  },
  FACEBOOK: {
    first: [],
    second: [],
    third: [],
  },
  TIKTOK: {
    first: [],
  },
};

const ttcmCampaignInitState: TTCMCampaignState = {
  id: '',
  name: '',
  beginDate: '',
  endDate: '',
  description: '',
  instructions: '',
  algorithm: initialTTCMAlgorithmState,
  tags: [],
  keywords: [],
  tagline: '',
  ttcmCampaignId: '',
  budget: '',
  currency: '',
  target: '',
  targetVideo: '',
};

const walkthroughState = {
  status: false,
};

const initialState = {
  alerts: alerts,
  newCampaign: campaignInitState,
  ttcmCampaign: ttcmCampaignInitState,
  ttcmCampaignList: [] as TTCMCampaignState[],
  settings: settings,
  user: user,
  profile: profile,
  coiin,
  channelMedia,
  walkthroughState,
};

export default initialState;
