import { PayloadAction } from '@reduxjs/toolkit';
import { TTCMCampaignState } from '../../types';

export const UPDATE_TTCM_CAMPAIGN = 'UPDATE_TTCM_CAMPAIGN';
export const RESET_TTCM_CAMPAIGN = 'RESET_TTCM_CAMPAIGN';

export const updateTTCMCampaign = (data: TTCMCampaignState): PayloadAction<TTCMCampaignState> => {
  return {
    type: UPDATE_TTCM_CAMPAIGN,
    payload: data,
  };
};

export const resetTTCMCampaign = (): PayloadAction<string | null> => {
  return {
    type: RESET_TTCM_CAMPAIGN,
    payload: null,
  };
};
