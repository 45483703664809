import { supportEmail } from './affiliateURLs';
import { brandManual, generalFaqs } from './constants';

interface MenuObject {
  name: string;
  to: string;
  icon: string;
  enabled: boolean;
  href?: string;
}

export const getRoutesMapping = (params: { company: string; ttcm: boolean }): MenuObject[] => {
  const { company, ttcm } = params;
  if (ttcm) {
    return [
      { name: 'dashboard', to: '/ttcm/dashboard', icon: 'dashboard', enabled: true },
      { name: 'campaigns', to: '/ttcm/campaigns', icon: 'campaign', enabled: true },
      { name: 'Add Campaign', to: '/ttcm/addCampaign', icon: 'newCampaign', enabled: true },
    ];
  }
  return [
    { name: 'dashboard', to: '/dashboard', icon: 'dashboard', enabled: true },
    { name: 'campaigns', to: '/dashboard/campaigns', icon: 'campaign', enabled: true },
    { name: 'market data', to: '/dashboard/marketData', icon: 'trending', enabled: false },
    { name: 'Create Campaign', to: '/dashboard/newCampaign', icon: 'newCampaign', enabled: true },
    { name: 'Crypto Wallet', to: '/dashboard/paymentsAccount', icon: 'crypto', enabled: true },
    {
      name: 'Admin',
      to: '/dashboard/admin/management',
      icon: 'admin',
      enabled: company === 'raiinmaker' || false,
    },
    {
      name: 'Audit campaigns',
      to: '/dashboard/admin/audit-campaigns',
      icon: 'campaign',
      enabled: company === 'raiinmaker' || false,
    },
    {
      name: 'Management',
      to: '/dashboard/admin/userManagement',
      icon: 'management',
      enabled: company === 'raiinmaker' || false,
    },
    {
      name: 'User List',
      to: '/dashboard/admin/userList',
      icon: 'list',
      enabled: company === 'raiinmaker' || false,
    },
    {
      name: 'Walkthrough',
      to: '/dashboard/admin/walkthrough',
      icon: 'walkthrough',
      enabled: company === 'raiinmaker' || false,
    },
    {
      name: 'Credits to Users',
      to: '/dashboard/admin/credits',
      icon: 'credits',
      enabled: company === 'raiinmaker' || false,
    },
  ];
};

export const geToolRoutes = (): MenuObject[] => {
  return [
    { name: 'Setting', to: '/dashboard/profile', icon: 'setting', enabled: true },
    // { name: 'FAQ', to: '', icon: 'faq', enabled: true, href: faqUrl },
    { name: 'Help & Support', to: '', icon: 'support', enabled: true, href: supportEmail },
    { name: 'General FAQ', to: '', icon: 'faq', enabled: true, href: generalFaqs },
    { name: 'Raiinmaker Brand Manual', to: '', icon: 'support', enabled: true, href: brandManual },
  ];
};
