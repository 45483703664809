import initialState from '../initialState';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { TTCMCampaignState } from '../../types';
import { ADD_TTCM_CAMPAIGN, REMOVE_TTCM_CAMPAIGN } from '../actions/ttcmCampaignList';

const ttcmCampaignList = createReducer(initialState.ttcmCampaignList, {
  [ADD_TTCM_CAMPAIGN]: (state, action: PayloadAction<TTCMCampaignState>) => {
    const data = [...state];
    data.push(action.payload);
    return data;
  },
  [REMOVE_TTCM_CAMPAIGN]: (state, action: PayloadAction<number>) => {
    const data = [...state];
    data.splice(action.payload, 1);
    return data;
  },
});

export default ttcmCampaignList;
