import React, { FC, useEffect } from 'react';
import ReactSelect from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { CoiinReducerTypes, IconTypes } from '../../types';
import './topbar.scss';
import raiinmakerLogo from '../../assets/png/raiinmaker.png';
import profileIcon from '../../assets/svg/topbar/profileIcon.svg';
import coiinIcon from '../../assets/png/coiin.png';
import { fetchCoiinValue } from '../../store/middlewares';
import { Menu } from '@material-ui/core';
import { useHistory } from 'react-router';
import { geToolRoutes } from '../../helpers/routesMapping';
import settingIcon from '../../assets/svg/sidebar/settingIcon.svg';
import faqIcon from '../../assets/svg/sidebar/faqIcon.svg';
import supportIcon from '../../assets/svg/sidebar/supportIcon.svg';
import logoutIcon from '../../assets/svg/sidebar/logoutIcon.svg';
import { showAppLoader } from '../../store/actions/settings';
import { logoutUser } from '../../store/actions/user';
import { ApiClient } from '../../services/apiClient';
import PortalToggle from '../PortalToggle';

const Icons: IconTypes = {
  setting: settingIcon,
  faq: faqIcon,
  support: supportIcon,
};

const Topbar: FC = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { coiinValue } = useSelector((state: { coiin: CoiinReducerTypes }) => state.coiin);
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const open = Boolean(anchorEl);
  const toolList = geToolRoutes();

  useEffect(() => {
    dispatch(fetchCoiinValue());
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      dispatch(showAppLoader({ flag: true, message: 'Ending your session!' }));
      dispatch(logoutUser());
      await ApiClient.logout();
      dispatch(showAppLoader({ flag: false, message: '' }));
    } catch (error) {
      dispatch(showAppLoader({ flag: false, message: '' }));
    }
  };

  return (
    <div className="topbarWrapper">
      <PortalToggle />
      <div className="topbarMenu">
        <div className="searchbarWrapper">
          <img src={raiinmakerLogo} alt="Raiinmaker" />
          <ReactSelect placeholder="Search" />
        </div>
        <div className="iconWrapper">
          <div className="coiinValue">
            <img src={coiinIcon} />
            <p>{coiinValue || ''} USD</p>
          </div>
          <div className="profile" onClick={handleClick}>
            <img src={profileIcon} />
          </div>
          <div>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              aria-labelledby="basic-demo-button"
              style={{ top: '60px' }}
            >
              {toolList &&
                toolList.map((item) => {
                  if (!item.enabled) return;
                  return (
                    <div key={item.name} className="menuWrapper">
                      <div
                        className={`itemsWrapper ${item.to === location.pathname ? '' : ''}`}
                        onClick={
                          item.to
                            ? () => {
                                push(item.to);
                                handleClose();
                              }
                            : () => {
                                window.open(item.href, '_blank');
                                handleClose();
                              }
                        }
                      >
                        <img src={Icons?.[item.icon]} alt="raiinmaker dashboard" />
                        <p>{item.name}</p>
                      </div>
                    </div>
                  );
                })}
              <div className="menuWrapper">
                <div className="itemsWrapper" onClick={() => handleLogout()}>
                  <img src={logoutIcon} alt="raiinmaker dashboard" />
                  <p>Logout</p>
                </div>
              </div>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
