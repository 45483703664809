import React, { FC, useEffect, useState } from 'react';
import TierDetailsLayout from '../../sections/TierDetailsLayout';
import TierHeader from '../TierHeader';
import './campaignDemographics.scss';
import usersIcon from '../../assets/svg/tiers/users.svg';
import { CampaignUsersTypes, DemographicParamTypes, UserDemographicsTypes } from '../../types';
import { useLocation } from 'react-router-dom';
import Pagination from '../../components/Pagination/Pagination';
import { useDispatch } from 'react-redux';
import { ApiClient } from '../../services/apiClient';
import { showErrorAlert } from '../../store/actions/alerts';
import { CircularProgress } from '@material-ui/core';

const CampaignDemographics: FC = () => {
  const dispatch = useDispatch();
  const {
    state: { campaignId, month, endDate, startDate },
  }: DemographicParamTypes = useLocation();
  const [skip, setSkip] = useState(0);
  const [take] = useState(20);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [demographics, setDemographics] = useState<UserDemographicsTypes>();

  useEffect(() => {
    setIsLoading(true);
    ApiClient.getDemographics({ campaignId, startDate, endDate, month, skip, take })
      .then((res) => {
        setDemographics(res);
      })
      .catch((error) => dispatch(showErrorAlert(error)))
      .finally(() => setIsLoading(false));
  }, [skip]);

  // Take paginated value from Pagination component
  const getValue = (skip: number) => {
    setSkip(skip);
  };

  return (
    <TierDetailsLayout>
      <TierHeader image={usersIcon} title="Tier 2: Campaign Demographics" />
      <div className="campaignDemographics">
        <div className="tableWrapper">
          <table>
            <thead>
              <tr>
                <th>Username</th>
                <th>Age Range</th>
                <th>Location</th>
              </tr>
            </thead>
            {isLoading ? (
              <div className="p-4">
                <CircularProgress size={24} />
              </div>
            ) : (
              <tbody>
                {demographics?.participants?.map(({ id, user: { profile } }: CampaignUsersTypes) => (
                  <tr key={id}>
                    <td>{profile?.username}</td>
                    <td>{profile?.ageRange}</td>
                    {profile?.city && profile?.country ? (
                      <td>
                        {profile?.city}, {profile?.country}
                      </td>
                    ) : (
                      <td></td>
                    )}
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
      {demographics && demographics?.count > 20 && (
        <div className="mt-10">
          <Pagination getValue={getValue} skip={skip} take={take} total={demographics?.count} />
        </div>
      )}
    </TierDetailsLayout>
  );
};

export default CampaignDemographics;
