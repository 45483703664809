import { FormControlLabel, Checkbox } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import './campaignSetupForm.scss';
import TwitterPhone from '../../../assets/png/socialPlatForms/TwitterPhone.png';
import InstagramPhone from '../../../assets/png/socialPlatForms/IGPhone.png';
import TiktokPhone from '../../../assets/png/socialPlatForms/TikTokPhone.png';
import FacebookPhone from '../../../assets/png/socialPlatForms/FBPhone.png';
import { SOCIAL_CHANNEL } from '../../../helpers/constants';

interface Props {
  socialMediaType: SOCIAL_CHANNEL[];
  handleChange: (val: SOCIAL_CHANNEL[]) => void;
  selectAllByDefault: boolean;
}

interface IconsObject {
  [key: string]: string;
  TWITTER: string;
  FACEBOOK: string;
  TIKTOK: string;
  INSTAGRAM: string;
}

export const getSocialIcon: IconsObject = {
  TWITTER: TwitterPhone,
  INSTAGRAM: InstagramPhone,
  TIKTOK: TiktokPhone,
  FACEBOOK: FacebookPhone,
};

export const socialMediaTypeMenu: SOCIAL_CHANNEL[] = [
  SOCIAL_CHANNEL.TWITTER,
  SOCIAL_CHANNEL.INSTAGRAM,
  SOCIAL_CHANNEL.FACEBOOK,
  SOCIAL_CHANNEL.TIKTOK,
];

const SocialMediaTypeInput: React.FC<Props> = ({ socialMediaType, handleChange, selectAllByDefault }) => {
  const [all, setAll] = useState(selectAllByDefault);

  useEffect(() => {
    if (socialMediaType?.length === 4) setAll(true);
  }, []);

  const selectAll = (checked: boolean) => {
    setAll(checked);
    if (checked) {
      const channels = [...socialMediaType];
      socialMediaTypeMenu.forEach((item) => {
        if (!channels.includes(item)) {
          channels.push(item);
        }
      });
      handleChange(channels);
    }
    if (!checked) {
      handleChange([]);
    }
    // if (all || selectAllByDefault) {
    //   const channels = [...socialMediaType];
    //   socialMediaTypeMenu.forEach((item) => {
    //     if (!channels.includes(item)) {
    //       debugger;
    //       channels.push(item);
    //     }
    //   });
    //   handleChange(channels);
    // } else {
    //   handleChange([]);
    // }
  };

  // useEffectSkipFirst(selectAll, [all, selectAllByDefault]);

  const handleSocialSelect = (val: SOCIAL_CHANNEL) => {
    const channels = [...socialMediaType];
    if (channels.includes(val)) {
      const index = channels.findIndex((item) => item === val);
      channels.splice(index, 1);
      setAll(false);
    } else {
      channels.push(val);
      if (channels.length === 4) setAll(true);
    }
    handleChange(channels);
  };

  return (
    <div className="socialMediaTypeInputWrapper">
      <p>
        <span>1/3</span> Choose the social media platform to promote your campaign!
      </p>
      <div className="socialMediaListWrapper">
        {socialMediaTypeMenu.map((item, index) => (
          <div key={index} className="socialPlatForms">
            <div
              onClick={() => handleSocialSelect(item)}
              className={`socialMedia  ${socialMediaType.includes(item) ? 'selectedField' : ''}`}
            >
              <div className="imageWrapper">
                <img src={getSocialIcon[item]} alt="social-icon" />
              </div>
            </div>
            <p>{item}</p>
          </div>
        ))}
      </div>
      <div className="checkboxWrapper">
        <FormControlLabel
          control={
            <Checkbox
              checked={all || selectAllByDefault}
              style={{ color: '#000', transform: 'scale(1.6)' }}
              name="Brand Agreement"
              onChange={(e, checked) => {
                selectAll(checked);
                // setAll(checked);
              }}
            />
          }
          label={<p className="allChannelsTitle">All Channels</p>}
        />
      </div>
    </div>
  );
};

export default SocialMediaTypeInput;
