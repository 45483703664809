import { useSelector } from 'react-redux';
import { RootStoreState } from '../store/reducers';
import { TTCMCampaignState } from '../types';

const useStoreTTCMCampaignSelector = (): TTCMCampaignState => {
  const campaign = (state: RootStoreState) => state.ttcmCampaign;
  return useSelector(campaign);
};

export default useStoreTTCMCampaignSelector;
