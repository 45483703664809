import React, { FC, Fragment, useEffect, useState } from 'react';
import PrimaryCard from '../../components/CryptoCard/PrimaryCard';
import CustomButton from '../../components/CustomButton';
import { PurchaseDialog } from '../../components/PurchaseDialog';
import Divider from '../../componentsv2/Divider';
import { ApiClient } from '../../services/apiClient';
import { useDispatch, useSelector } from 'react-redux';
import { showErrorAlert } from '../../store/actions/alerts';
import {
  FundingWallet as FundingWalletType,
  PaymentMethodTypes,
  ProfileTypes,
  SupportedCurrenciesTypes,
} from '../../types';
import { FiPlus } from 'react-icons/fi';
import { BsCreditCard2BackFill } from 'react-icons/bs';
import { CircularProgress } from '@material-ui/core';
import GenericModal from '../../components/GenericModal';
import DepositCryptoForm from '../../components/Forms/DepositCryptoForm';
import { Elements } from '@stripe/react-stripe-js';
import { CardSetupForm } from '../../components/CardSetupForm';
import { loadStripe } from '@stripe/stripe-js';
import { stripePubKey } from '../../apiConfig.json';
import { WithdrawFormModal } from '../../components/Forms/WithdrawForm';
import { CRYPTOS, FUNDINGWALLET, RAIINMAKER } from '../../helpers/constants';
import './cryptoWallet.scss';

interface ICryptoWalletProps {
  isCreateCampaign?: boolean;
  activeTab?: typeof FUNDINGWALLET | typeof CRYPTOS;
  fundingWalletData?: FundingWalletType[];
}

const env = process.env.REACT_APP_STAGE === undefined ? 'local' : process.env.REACT_APP_STAGE;
const stripeKey = (stripePubKey as { [key: string]: string })[env];
const stripePromise = loadStripe(stripeKey);

const CryptoWallet: FC<ICryptoWalletProps> = ({ isCreateCampaign, activeTab, fundingWalletData }) => {
  const dispatch = useDispatch();
  const { profile } = useSelector((state: { profile: ProfileTypes }) => state);
  const [purchaseCoiin, setPurchaseCoiin] = useState(false);
  const [fundingWallet, setFundingWallet] = useState<FundingWalletType[]>(fundingWalletData || []);
  const [isCurrencyLoading, setIsCurrencyLoading] = useState(false);
  const [coiins, setCoiins] = useState<FundingWalletType[]>([]);
  const [otherCurrencies, setOtherCurrencies] = useState<FundingWalletType[]>([]);
  const [creditCards, setCreditCards] = useState<PaymentMethodTypes[]>([]);
  const [isWalletLoading, setIsWalletLoading] = useState<boolean>(false);
  const [openCrypto, setOpenCrypto] = useState(false);
  const [refetch, setRefetch] = useState(0);
  const [currencyList, setCurrencyList] = useState<SupportedCurrenciesTypes[]>([]);
  const [isCreditCard, setIsCreditCard] = useState(false);
  const [removalId, setRemovalId] = useState('');
  const [isRemoveLoading, setIsRemoveLoading] = useState<boolean>(false);
  const [walletRefetch, setWalletRefetch] = useState(0);
  const [selectedCurrency, setSelectedCurrency] = useState<FundingWalletType | undefined>();
  const [withdrawForm, showWithdrawForm] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsCurrencyLoading(true);
      await ApiClient.getFundingWallet()
        .then((resp) => {
          const filterCoiin = resp.filter((item) => item.type === 'COIIN');
          const filterOtherCurrencies = resp.filter((item) => item.type !== 'COIIN');
          setCoiins(filterCoiin);
          setOtherCurrencies(filterOtherCurrencies);
          setFundingWallet(resp);
        })
        .catch((error) => {
          dispatch(showErrorAlert(error.message));
        })
        .finally(() => {
          setIsCurrencyLoading(false);
        });
    };
    if (fundingWalletData) {
      const filterCoiin = fundingWalletData.filter((item) => item.type === 'COIIN');
      const filterOtherCurrencies = fundingWalletData.filter((item) => item.type !== 'COIIN');
      setCoiins(filterCoiin);
      setOtherCurrencies(filterOtherCurrencies);
      setFundingWallet(fundingWalletData);
    } else {
      fetchData();
    }
  }, [refetch]);

  useEffect(() => {
    setIsWalletLoading(true);
    ApiClient.getPaymentMethods()
      .then((res) => setCreditCards(res))
      .catch((err) => dispatch(showErrorAlert(err.message)))
      .finally(() => setIsWalletLoading(false));
  }, [walletRefetch]);

  useEffect(() => {
    ApiClient.getSupportedCurrencies()
      .then((res) => setCurrencyList(res))
      .catch((error) => dispatch(showErrorAlert(error.message)));
  }, []);

  const handleRefetch = () => {
    setRefetch(new Date().getTime());
  };

  const handleWalletRefetch = () => {
    setWalletRefetch(new Date().getTime());
  };

  const handleRemoveCreditCard = async (id: string) => {
    setRemovalId(id);
    setIsRemoveLoading(true);
    ApiClient.removePaymentMethod({ paymentMethodId: id })
      .then((res) => console.log(res))
      .catch((err) => dispatch(showErrorAlert(err.message)))
      .finally(() => {
        setIsRemoveLoading(false);
        handleWalletRefetch();
      });
  };

  const handleWithdrawModalClose = () => {
    showWithdrawForm(false);
    setSelectedCurrency(undefined);
  };

  const handleRefreshBalance = (value: boolean) => {
    if (value) handleRefetch();
  };

  const toolTipMap = {
    title: 'Token',
    value: 'Balance',
    network: 'Network',
  };
  const toolTipForCreditCard = {
    title: 'Credit Card Type',
    value: 'Last Four Digits of Card',
  };

  return (
    <div className="cryptoWalletWrapper">
      <GenericModal open={purchaseCoiin} size={'small'} onClose={() => setPurchaseCoiin(false)}>
        <PurchaseDialog setOpen={setPurchaseCoiin} handleRefreshBalance={handleRefreshBalance} />
      </GenericModal>
      <GenericModal open={openCrypto} onClose={() => setOpenCrypto(false)} size="small">
        <DepositCryptoForm cryptoList={currencyList} callback={handleRefetch} fundingWallet={fundingWallet} />
      </GenericModal>
      {selectedCurrency && (
        <WithdrawFormModal open={withdrawForm} onClose={handleWithdrawModalClose} currency={selectedCurrency} />
      )}
      <GenericModal open={isCreditCard} persist={true} size={'small'} onClose={() => setIsCreditCard(false)}>
        <Elements stripe={stripePromise}>
          <CardSetupForm setModal={setIsCreditCard} callback={handleWalletRefetch} />
        </Elements>
      </GenericModal>
      {isCreateCampaign && (
        <div className="fundingWalletTitle">
          <p>FUNDING WALLET</p>
        </div>
      )}
      <div className="coiinSection">
        <div className="coiinTitleBar">
          <h1>Coiin</h1>
          {profile.company != RAIINMAKER && (
            <CustomButton onClick={() => setPurchaseCoiin(true)} className="buttonStyles">
              <FiPlus size={20} /> <span>Purchase</span>
            </CustomButton>
          )}
        </div>
        <Divider />
        <div className="balance">
          {isCurrencyLoading ? (
            <CircularProgress size={22} />
          ) : (
            <Fragment>
              {coiins.length ? (
                <Fragment>
                  {coiins?.map((coiin: FundingWalletType) => (
                    <div
                      key={coiin.type}
                      onClick={() => {
                        setSelectedCurrency(coiin);
                        showWithdrawForm(true);
                      }}
                      className="coiinCardWrap"
                    >
                      <PrimaryCard
                        key={coiin.type}
                        title={'Balance'}
                        network={coiin.network}
                        value={coiin.balance}
                        icon={coiin.symbolImageUrl}
                        tooltipTitle={toolTipMap.title}
                        tooltipValue={toolTipMap.value}
                        tooltipNetwork={toolTipMap.network}
                      />
                    </div>
                  ))}
                </Fragment>
              ) : (
                <p className="nonAvailable">Empty</p>
              )}
            </Fragment>
          )}
        </div>
      </div>
      {activeTab === FUNDINGWALLET && (
        <div className="cardSectionWrapper">
          <div className="titleBar">
            <h1>Cards</h1>
            {profile.company != RAIINMAKER && (
              <CustomButton onClick={() => setIsCreditCard(true)} className="buttonStyles">
                <FiPlus size={20} /> <span>Add Credit Card</span>
              </CustomButton>
            )}
          </div>
          <Divider />
          <div className="cardListArea">
            {isWalletLoading ? (
              <CircularProgress size={22} />
            ) : (
              <Fragment>
                {creditCards.length ? (
                  <Fragment>
                    {creditCards?.map((payment: PaymentMethodTypes) => (
                      <PrimaryCard
                        key={payment.id}
                        title={payment.brand}
                        value={`*${payment.last4}`}
                        expMonth={payment.expMonth}
                        expYear={payment.expYear}
                        tooltipTitle={toolTipForCreditCard['title']}
                        tooltipValue={toolTipForCreditCard['value']}
                        icon={<BsCreditCard2BackFill />}
                        id={payment.id}
                        removeMethod={handleRemoveCreditCard}
                        removeLoading={isRemoveLoading && removalId === payment.id}
                      />
                    ))}
                  </Fragment>
                ) : (
                  <p className="nonAvailable">None Available</p>
                )}
              </Fragment>
            )}
          </div>
        </div>
      )}
      {activeTab === CRYPTOS && (
        <div className="cryptosSection">
          <div className="titleBar">
            <h1>Cryptos</h1>
            <CustomButton onClick={() => setOpenCrypto(true)} className="buttonStyles">
              <FiPlus size={20} /> <span>Deposit</span>
            </CustomButton>
            {/* <CustomButton
            onClick={() => setOpenDialog(true)}
            className="bg-cyberYellow rounded-full px-3 py-2 w-auto flex items-center gap-2"
          >
            <FiPlus size={20} /> <span>Register ERC20 Token</span>
          </CustomButton> */}
          </div>
          <Divider />
          <div className="cryptoList">
            {isCurrencyLoading ? (
              <CircularProgress size={22} />
            ) : (
              <Fragment>
                {otherCurrencies.length ? (
                  <Fragment>
                    {otherCurrencies?.map((coiin: FundingWalletType) => (
                      <div
                        key={coiin.type}
                        onClick={() => {
                          setSelectedCurrency(coiin);
                          showWithdrawForm(true);
                        }}
                      >
                        <PrimaryCard
                          title={coiin.type}
                          network={coiin.network}
                          value={coiin.balance}
                          icon={coiin.symbolImageUrl}
                          tooltipTitle={toolTipMap.title}
                          tooltipValue={toolTipMap.value}
                          tooltipNetwork={toolTipMap.network}
                        />
                      </div>
                    ))}
                  </Fragment>
                ) : (
                  <p className="nonAvailable">None Available</p>
                )}
              </Fragment>
            )}
          </div>
        </div>
      )}

      {/* <div className="customTokenSection">
        <div className="titleBar">
          <h1>Custom Tokens</h1>
          <CustomButton className="buttonStyles" onClick={() => setOpenDialog(true)}>
            <FiPlus size={20} /> <span>Register</span>
          </CustomButton>
        </div>
        <Divider />
        <div className="customTokenList">
          {transfers.length ? <div></div> : <p className="nonAvailable">None Available</p>}
        </div>
      </div> */}

      {/* <div className="my-6 flex gap-6">
        <CustomButton
          onClick={() => setOpenCrypto(true)}
          className="bg-cyberYellow rounded-full px-3 py-2 w-auto flex items-center gap-2"
        >
          <FiPlus size={20} /> <span>Deposit Crypto</span>
        </CustomButton>
        <CustomButton
          onClick={() => setOpenDialog(true)}
          className="bg-cyberYellow rounded-full px-3 py-2 w-auto flex items-center gap-2"
        >
          <FiPlus size={20} /> <span>Register ERC20 Token</span>
        </CustomButton>
      </div>
      {isCurrencyLoading ? (
        <CircularProgress size={22} />
      ) : (
        <div className="mb-6 flex flex-wrap gap-4">
          {otherCurrencies?.map((coiin: FundingWalletTypes) => (
            <div
              key={coiin.type}
              onClick={() => {
                setSelectedCurrency(coiin);
                showWithdrawForm(true);
              }}
            >
              <PrimaryCard
                title={coiin.type}
                network={coiin.network}
                value={coiin.balance}
                icon={coiin.symbolImageUrl}
                tooltipTitle={toolTipMap.title}
                tooltipValue={toolTipMap.value}
                tooltipNetwork={toolTipMap.network}
              />
            </div>
          ))}
        </div>
      )} */}
    </div>
  );
};

export default CryptoWallet;
