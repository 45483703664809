import React from 'react';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { CampaignState, ChannelMediaObject, ChannelMediaTypes, FileObject } from '../../../types';
import { showErrorAlert } from '../../../store/actions/alerts';
import useStoreCampaignSelector from '../../../hooks/useStoreCampaignSelector';
import { ActionsProps } from '../../NewCampaign/StepsContent';
import Actions from '../../NewCampaign/Actions';
import { channelMediaAction, removeChannelMediaAction, updateCampaign } from '../../../store/actions/campaign';
import './campaignMediaForm.scss';
import MediaSteps from './MediaSteps';
import { AI_GENERATIVE, CAMPAIGN, SOCIAL_CHANNEL } from '../../../helpers/constants';

type PLATFORM_TYPE = SOCIAL_CHANNEL | typeof CAMPAIGN | typeof AI_GENERATIVE;

const CampaignMediaForm: React.FC<ActionsProps> = ({ activeStep, handleBack, handleNext, firstStep, finalStep }) => {
  const dispatch = useDispatch();
  const campaign = useStoreCampaignSelector();
  const socialMediaType = [CAMPAIGN, ...campaign.config.socialMediaType];
  const [imageToImage, setImageToImage] = useState(campaign.config.imageToImage);
  const [campaignImage, setCampaignImage] = useState(campaign.campaignImage);
  const [channelMedia, setChannelMedia] = useState(campaign.config.channelMedia);
  const channelMediaList = useSelector((state: { channelMedia: ChannelMediaTypes }) => state.channelMedia);
  const [platform, setPlatform] = useState<PLATFORM_TYPE>(CAMPAIGN);
  const [step, setStep] = useState<number>(0);
  const [selectedModels, setSelectedModels] = useState<string[]>(campaign.modelIds);

  const onCampaignImageSuccess = (data: FileObject) => {
    setCampaignImage(data);
  };

  const updateModelData = (data: string) => {
    const list = [...selectedModels];
    const index = list.indexOf(data);
    if (index > -1) {
      list.splice(index, 1);
    } else {
      list.push(data);
    }
    setSelectedModels(list);
  };

  const onSuccess = (channel: string, list: ChannelMediaObject[]) => {
    const allChannels = { ...channelMedia };
    allChannels[channel] = list;
    setChannelMedia(allChannels);
  };

  const handleChannelMedias = (channel: SOCIAL_CHANNEL, ratio: string, data: FileObject, slug: string) => {
    const media = {
      channel: channel,
      media: data,
      slug,
      ratio,
      isDefault: channelMedia[channel].length < 1 ? true : false,
    };
    dispatch(channelMediaAction(media, ratio, channel));
    if (channel === SOCIAL_CHANNEL.TIKTOK) {
      onSuccess(channel, [...channelMediaList.TWITTER.first, media]);
    } else {
      onSuccess(channel, [
        ...channelMediaList[channel].first,
        ...channelMediaList[channel].second,
        ...channelMediaList[channel].third,
        media,
      ]);
    }
  };

  const removeChannelMedia = (index: number, channel: ChannelMediaObject, ratio: string) => {
    if (channelMedia[channel.channel].length > 0) {
      let filterMedia: ChannelMediaObject[];
      dispatch(removeChannelMediaAction(ratio, channel.channel, index));
      // remove media from channel media list
      const updatedChannelMedias = { ...channelMedia };
      if (channel.id) {
        filterMedia = updatedChannelMedias[channel.channel].filter((x) => x.id !== channel.id);
      } else {
        filterMedia = updatedChannelMedias[channel.channel].filter((x) => x.slug !== channel.slug);
      }
      const findDefaultMedia = filterMedia.find((x) => x.isDefault === true);
      if (!findDefaultMedia) filterMedia[0] = { ...filterMedia[0], isDefault: true };
      updatedChannelMedias[channel.channel] = filterMedia;
      setChannelMedia(updatedChannelMedias);
    } else {
      return dispatch(showErrorAlert('Default media is required'));
    }
  };

  const onError = (msg: string) => {
    dispatch(showErrorAlert(msg));
  };

  const next = () => {
    if (validateInputs()) {
      const augmentedCampaign: CampaignState = {
        ...campaign,
        campaignImage,
        modelIds: selectedModels,
        config: {
          ...campaign.config,
          channelMedia: channelMedia,
          imageToImage,
        },
      };
      dispatch(updateCampaign(augmentedCampaign));
      if (!campaign.config.aIGenerative) {
        const lastChannel = socialMediaType[socialMediaType.length - 1];
        if (lastChannel === platform) handleNext();
        else {
          setPlatform(socialMediaType[step + 1] as PLATFORM_TYPE);
          setStep((pre) => pre + 1);
        }
      } else {
        if (platform === AI_GENERATIVE) handleNext();
        else {
          setPlatform(AI_GENERATIVE);
          setStep((pre) => pre + 1);
        }
      }
    }
  };

  const back = () => {
    if (platform === socialMediaType[0]) handleBack();
    else {
      setStep((pre) => pre - 1);
      setPlatform(campaign.config.aIGenerative ? CAMPAIGN : (socialMediaType[step - 1] as PLATFORM_TYPE));
    }
  };

  const validateInputs = (): boolean => {
    let validated = true;
    if (!campaignImage.filename) {
      dispatch(showErrorAlert('Campaign image is required'));
      return (validated = false);
    }
    if (!campaign.config.aIGenerative) {
      if (!channelMedia.TWITTER.length && platform === SOCIAL_CHANNEL.TWITTER) {
        dispatch(showErrorAlert(`Default Media is required for Twitter`));
        return (validated = false);
      }
      if (!channelMedia.INSTAGRAM.length && platform === SOCIAL_CHANNEL.INSTAGRAM) {
        dispatch(showErrorAlert(`Default Media is required for Instagram`));
        return (validated = false);
      }
      if (!channelMedia.FACEBOOK.length && platform === SOCIAL_CHANNEL.FACEBOOK) {
        dispatch(showErrorAlert(`Default Media is required for Facebook`));
        return (validated = false);
      }
      if (!channelMedia.TIKTOK.length && platform === SOCIAL_CHANNEL.TIKTOK) {
        dispatch(showErrorAlert(`Default Media is required for Tiktok`));
        return (validated = false);
      }
    } else {
      return validated;
    }
    return validated;
  };

  return (
    <Box className="campaignMediaFormWrapper">
      <MediaSteps
        // steps={steps}
        campaignImage={campaignImage}
        channelMedia={channelMedia}
        onCampaignImageSuccess={onCampaignImageSuccess}
        socialMediaType={socialMediaType}
        onError={onError}
        handleChannelMedia={handleChannelMedias}
        removeChannelMedia={removeChannelMedia}
        updateModelData={updateModelData}
        platform={platform}
        imageToImage={imageToImage}
        setImageToImage={setImageToImage}
        selectedModels={selectedModels}
      />

      <Actions
        activeStep={activeStep}
        firstStep={firstStep}
        finalStep={finalStep}
        handleBack={back}
        handleNext={next}
      />
    </Box>
  );
};

export default CampaignMediaForm;
