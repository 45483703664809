import { PayloadAction } from '@reduxjs/toolkit';
export const SHOW_APP_LOADER = 'SHOW_APP_LOADER';
export const SHOW_TTCM = 'SHOW_TTCM';

export interface AppLoaderPayload {
  flag: boolean;
  message: string;
}

export const showAppLoader = (data: AppLoaderPayload): PayloadAction<AppLoaderPayload> => {
  return {
    type: SHOW_APP_LOADER,
    payload: data,
  };
};

export const toggleTTCM = (flag: boolean): PayloadAction<boolean> => {
  return {
    type: SHOW_TTCM,
    payload: flag,
  };
};
