import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import './styles.scss';
import SelectCurrency from '../../SelectCurrency';
import FundingWallet from '../../../sections/CryptoWallet';
import { ErrorObject, FundingWallet as FundingWalletTypes } from '../../../types';
import CustomInput from '../../../components/CustomInput';
import { TTCMActionsProps } from './Content';
import Actions from '../../../components/NewCampaign/Actions';
import { useDispatch } from 'react-redux';
import { ApiClient } from '../../../services/apiClient';
import { BSC, COIIN } from '../../../helpers/constants';
import useStoreTTCMCampaignSelector from '../../../hooks/useStoreTTCMCampaignSelector';
import { updateTTCMCampaign } from '../../../store/actions/ttcmCampaign';

const TTCMCampaignBudget: FC<TTCMActionsProps> = ({ activeStep, firstStep, finalStep, handleBack, handleNext }) => {
  const ttcmCampaign = useStoreTTCMCampaignSelector();
  const [budget, setBudget] = useState(ttcmCampaign.budget);
  const [currency, setCurrency] = React.useState<string>('');
  const [errors, setErrors] = useState<ErrorObject>({});
  const [fundingWallet, setFundingWallet] = useState<FundingWalletTypes[]>([]);
  const [loading, setLoading] = useState(false);
  const campaignCurrency = ttcmCampaign.currency;
  const dispatch = useDispatch();
  const coiinOptions = !loading && fundingWallet ? fundingWallet.map((item) => `${item.type}-${item.network}`) : [];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const resp = await ApiClient.getFundingWallet();
      setFundingWallet(resp);
      const defalutCurrency = resp.find((x: FundingWalletTypes) => x.type.includes(COIIN) && x.network.includes(BSC));
      setCurrency(campaignCurrency || `${defalutCurrency?.type}-${defalutCurrency?.network}`);
      setLoading(false);
    };
    fetchData();
  }, []);

  const validateInputs = (): boolean => {
    return true;
  };

  const updateErrors = (name: string, data: string | string[] | null) => {
    const key = name;
    const value = data;
    const newErrors = { ...errors };
    if (!value || value.length === 0) {
      newErrors[key] = true;
    } else {
      newErrors[key] = false;
    }
    setErrors(newErrors);
  };

  const handleSelectToken = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrency(event.target.value);
    setBudget('');
  };

  const handleCoiinBudgetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (parseFloat(event.target.value) < 0) return;
    if (fundingWallet) {
      const token = fundingWallet.find((item) => `${item.type}-${item.network}` === currency);
      if (token) {
        setBudget(
          parseFloat(event.target.value) > parseFloat(token.balance) ? token.balance.toString() : event.target.value,
        );
        updateErrors('budget', event.target.value);
      }
    }
  };

  const next = () => {
    if (validateInputs()) {
      const augmentedCampaign = {
        ...ttcmCampaign,
        budget,
        currency,
      };
      dispatch(updateTTCMCampaign(augmentedCampaign));
      handleNext();
    }
  };

  return (
    <div className="budgetContainer">
      <h1 className="title">What Is Your campaign Budget? (AllCreators)</h1>
      <div className="selectFieldsWrapper">
        <div className="selectField">
          <SelectCurrency
            label="Currency*"
            value={currency}
            options={coiinOptions}
            handleOnChange={(event: ChangeEvent<HTMLSelectElement>) => {
              handleSelectToken(event);
            }}
          />

          <div className="selectFieldOutline">
            <CustomInput
              required={true}
              value={budget}
              onChange={handleCoiinBudgetChange}
              label="Amount"
              type="number"
              error={errors['budget']}
            />
          </div>
        </div>
      </div>

      <div className="fundingWallet">
        <FundingWallet isCreateCampaign={true} activeTab={'FundingWallet'} fundingWalletData={fundingWallet} />
      </div>

      <Actions
        activeStep={activeStep}
        firstStep={firstStep}
        finalStep={finalStep}
        handleBack={handleBack}
        handleNext={next}
      />
    </div>
  );
};

export default TTCMCampaignBudget;
