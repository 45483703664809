import React, { useEffect, useState } from 'react';
import './styles.scss';
import { CircularProgress } from '@material-ui/core';
import useStoreUserSelector from '../../../hooks/useStoreUserSelector';
import { ApiClient } from '../../../services/apiClient';
import { TIKTOK_AUTH_URL } from '../../../helpers/constants';
import { useDispatch } from 'react-redux';
import { setUserData } from '../../../store/actions/user';
import CustomButton from '../../../components/CustomButton';
import TiktokIcon from '../../../assets/png/socialIcons/TikTok-icon-glyph 5.png';
import { useHistory } from 'react-router';

const TTCMLogin: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const userData = useStoreUserSelector();
  const urlParams = new URLSearchParams(window.location.search);
  const authCode = urlParams.get('auth_code');
  const dispatch = useDispatch();
  const { push } = useHistory();

  const authenticate = () => {
    if (userData.isTiktokLinked) {
      push('/ttcm/dashboard');
    } else {
      window.location.replace(TIKTOK_AUTH_URL);
    }
  };

  useEffect(() => {
    if (authCode) {
      setLoading(true);
      ApiClient.linkTiktok(authCode)
        .then(() => {
          ApiClient.getAdminData().then((resp) => {
            dispatch(
              setUserData({
                ...userData,
                isTiktokLinked: resp.isTiktokLinked,
              }),
            );
          });
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [authCode]);

  if (loading)
    return (
      <div className="tiktokLoginPage">
        <CircularProgress />
      </div>
    );

  return (
    <div className="tiktokLoginPage">
      <img src={TiktokIcon} alt="tiktok" className="tiktokIcon" />
      <CustomButton
        className="w-48 h-12 mr-5 rounded-md text-black text-md border border-cyberYellow bg-cyberYellow"
        onClick={authenticate}
      >
        SignIn
      </CustomButton>
    </div>
  );
};

export default TTCMLogin;
