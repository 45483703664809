import React, { ChangeEvent, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import CustomButton from './CustomButton';
import buttonStyles from '../assets/styles/customButton.module.css';
import { ApiClient } from '../services/apiClient';
import { useDispatch } from 'react-redux';
import { setUserData } from '../store/actions/user';
import { useHistory } from 'react-router';

interface Props {
  open: boolean;
  email: string;
  idToken: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  callback?: (val: boolean) => void;
}

export const ChangePasswordDialog: React.FC<Props> = ({ open, email, idToken }) => {
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await ApiClient.updateAdminPassword({ password, idToken });
      ApiClient.login({ email, password }).then((resp) => {
        dispatch(
          setUserData({
            ...resp,
            isLoggedIn: true,
          }),
        );
        history.push('/dashboard/campaigns');
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <Dialog open={open}>
        <DialogTitle>Please change your password</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            onChange={handleChange}
            margin="dense"
            id="password"
            label="New Password"
            type="password"
            fullWidth
          />
        </DialogContent>
        <div className="flex justify-center pt-2 pb-4">
          <CustomButton onClick={handleSubmit} className={`${buttonStyles.buttonPrimary} `} loading={loading}>
            Submit
          </CustomButton>
        </div>
      </Dialog>
    </div>
  );
};
