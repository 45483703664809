import React, { FC, useState, useEffect } from 'react';
import headingStyles from '../../assets/styles/heading.module.css';
import { RedemptionTypes } from '../../types';
import styles from './userList.module.css';
import { ApiClient } from '../../services/apiClient';
import { useDispatch } from 'react-redux';
import { showErrorAlert } from '../../store/actions/alerts';

const RedemptionDetials: FC<{ userId: string }> = ({ userId }) => {
  const [redemptions, setRedemptions] = useState<RedemptionTypes>();
  const dispatch = useDispatch();

  useEffect(() => {
    ApiClient.getRedemptionRequirements({ userId })
      .then((response) => setRedemptions(response))
      .catch((error) => dispatch(showErrorAlert((error as Error).message)));
  }, []);

  return (
    <div>
      <h3 className={headingStyles.headingSm}>Redemption Details</h3>
      <h4 className={`${headingStyles.headingXs} mt-3`}>Twitter Redemptions:</h4>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr className={styles.tableHeadRow}>
              <th className={styles.tableColumn}>Linked</th>
              <th className={styles.tableColumn}>Followers</th>
              <th className={styles.tableColumn}>Requirement</th>
              <th className={styles.tableColumn}>Participation</th>
              <th className={styles.tableColumn}>Order Limit 24 hours Reached</th>
            </tr>
          </thead>
          <tbody>
            {redemptions ? (
              <tr className={styles.tableBodyRow}>
                <td className={styles.tableColumn}>{redemptions.twitterLinked ? 'True' : 'False'}</td>
                <td className={styles.tableColumn}>{redemptions.twitterfollowers}</td>
                <td className={styles.tableColumn}>{redemptions.twitterfollowersRequirement}</td>
                <td className={styles.tableColumn}>{redemptions.participation ? 'True' : 'False'}</td>
                <td className={styles.tableColumn}>
                  {redemptions.orderLimitForTwentyFourHoursReached ? 'True' : 'False'}
                </td>
              </tr>
            ) : (
              <p className="p-2">No twitter record found</p>
            )}
          </tbody>
        </table>
      </div>
      <h4 className={headingStyles.headingXs}>Tiktok Redemptions:</h4>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr className={styles.tableHeadRow}>
              <th className={styles.tableColumn}>Linked</th>
              <th className={styles.tableColumn}>Followers</th>
              <th className={styles.tableColumn}>Requirement</th>
              <th className={styles.tableColumn}>Participation</th>
              <th className={styles.tableColumn}>Order Limit 24 hours Reached</th>
            </tr>
          </thead>
          <tbody>
            <p className="p-2">No tiktok record found</p>
          </tbody>
        </table>
      </div>
      <h4 className={headingStyles.headingXs}> Redemptions:</h4>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr className={styles.tableHeadRow}>
              <th className={styles.tableColumn}>Linked</th>
              <th className={styles.tableColumn}>Followers</th>
              <th className={styles.tableColumn}>Requirement</th>
              <th className={styles.tableColumn}>Participation</th>
              <th className={styles.tableColumn}>Order Limit 24 hours Reached</th>
            </tr>
          </thead>
          <tbody>
            <p className="p-2">No facebook record found</p>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RedemptionDetials;
