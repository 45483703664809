import React from 'react';
import './styles.scss';
import { Creator, PointValues } from '../../../../helpers/mockData';
import { calculateTTCMInfluence, formatNumber } from '../../../../helpers/utils';

interface Props {
  creators: Creator[];
  currency: string;
  budget: number;
  pointValues: PointValues;
  campaignInfluence: number;
}

const CreatorsTable: React.FC<Props> = ({ creators, currency, pointValues, campaignInfluence, budget }) => {
  return (
    <div className="tableWrapper">
      <table>
        <thead>
          <tr className="tableRow">
            <th>Username</th>
            <th>Likes</th>
            <th>Shares</th>
            <th>Views</th>
            <th>Comments</th>
            <th>Downloads</th>
            <th>Influence</th>
            <th>% Influence</th>
            <th>Reward Amount</th>
          </tr>
        </thead>
        <tbody>
          {creators.map((creator) => {
            const influence = calculateTTCMInfluence(pointValues, creator.metrics);
            const percentageInfluence = ((influence / campaignInfluence) * 100).toFixed(2);
            const reward = (influence / campaignInfluence) * budget;
            return (
              <tr key={creator.name} className="hover:bg-gray-100 border-b-2 border-solid border-gray-100">
                <td className="px-7 py-5 text-left capitalize">{creator.username}</td>
                <td className="px-7 py-5 text-left">{formatNumber(creator.metrics.likes)}</td>
                <td className="px-7 py-5 text-left">{formatNumber(creator.metrics.shares)}</td>
                <td className="px-7 py-5 text-left">{formatNumber(creator.metrics.views)}</td>
                <td className="px-7 py-5 text-left">{formatNumber(creator.metrics.comments)}</td>
                <td className="px-7 py-5 text-left">{formatNumber(creator.metrics.downloads)}</td>
                <td className="px-7 py-5 text-left">{formatNumber(influence)}</td>
                <td className="px-7 py-5 text-left">{percentageInfluence}%</td>
                <td className="px-7 py-5 text-left">
                  {formatNumber(reward)} {currency.split('-')[0]}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CreatorsTable;
