import React, { useEffect } from 'react';
import { Switch } from 'react-router';
import { TTCMProtectedRoute } from '../../components/ProtectedRoute';
import './ttcm.scss';
import { useDispatch } from 'react-redux';
import { ApiClient } from '../../services/apiClient';
import { getProfile } from '../../store/actions/profile';
import Sidebar from '../../componentsv2/Sidebar';
import Topbar from '../../componentsv2/Topbar';
import { setUserData } from '../../store/actions/user';
import useStoreUserSelector from '../../hooks/useStoreUserSelector';
import TTCMLogin from '../TTCM/Login';
import TTCMDashboard from '../TTCM/Dashboard';
import TTCMCampaigns from '../TTCM/Campaigns';
import TTCMAddCampaign from '../TTCM/AddCampaign';

const TTCM: React.FC = () => {
  const dispatch = useDispatch();
  const userData = useStoreUserSelector();

  useEffect(() => {
    ApiClient.getProfile()
      .then((res) => {
        dispatch(getProfile(res.data));
        ApiClient.getAdminData()
          .then((res) => {
            dispatch(setUserData({ ...userData, ...res }));
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="home">
      <aside className="side">
        <Sidebar />
      </aside>
      <div className="content">
        <div className="topbar">
          <Topbar />
        </div>
        <div className="main">
          <Switch>
            <TTCMProtectedRoute exact path={'/ttcm/login'}>
              <TTCMLogin />
            </TTCMProtectedRoute>
            <TTCMProtectedRoute exact path={'/ttcm/dashboard'} requireTiktokLogin={true}>
              <TTCMDashboard />
            </TTCMProtectedRoute>
            <TTCMProtectedRoute exact path={'/ttcm/campaigns'} requireTiktokLogin={true}>
              <TTCMCampaigns />
            </TTCMProtectedRoute>
            <TTCMProtectedRoute exact path={'/ttcm/addCampaign'} requireTiktokLogin={true}>
              <TTCMAddCampaign />
            </TTCMProtectedRoute>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default TTCM;
