import React, { FC, useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import headingStyles from '../../assets/styles/heading.module.css';
import { UserTransactionHistoryTypes } from '../../types';
import Pagination from '../Pagination/Pagination';
import styles from './userList.module.css';
import { ApiClient } from '../../services/apiClient';
import { useDispatch } from 'react-redux';
import { showErrorAlert } from '../../store/actions/alerts';
import fileDownload from 'js-file-download';
import CustomButton from '../CustomButton';
import buttonStyles from '../../assets/styles/customButton.module.css';

const UserTransactionHistory: FC = () => {
  const dispatch = useDispatch();
  const { id }: { id: string } = useParams();
  const [transactionHistory, setTransactionHistory] = useState<UserTransactionHistoryTypes[]>([]);
  const [skip, setSkip] = useState<number>(0);
  const [take] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    ApiClient.getTransactionHistory({ skip, take, userId: id })
      .then((response) => {
        setTransactionHistory(response.items);
        setTotal(response.total);
      })
      .catch((error) => dispatch(showErrorAlert((error as Error).message)))
      .finally(() => setLoading(false));
  }, [skip]);

  const downloadRecord = () => {
    setDownloadLoading(true);
    ApiClient.downloadUserTransactions({ userId: id })
      .then((response: any) => {
        console.log(response);
        fileDownload(response, 'User Trannsaction History.csv');
      })
      .catch((error) => dispatch(showErrorAlert((error as Error).message)))
      .finally(() => setDownloadLoading(false));
  };

  // Take paginated value from Pagination component
  const getValue = (skip: number) => {
    setSkip(skip);
  };

  // Loading
  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <div className="flex flex-row justify-between items-center mb-3">
        <h3 className={headingStyles.headingSm}>User Transaction History</h3>
        <CustomButton className={buttonStyles.buttonPrimary} onClick={downloadRecord} loading={downloadLoading}>
          Download
        </CustomButton>
      </div>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr className={styles.tableHeadRow}>
              <th className={styles.tableColumn}>Action</th>
              <th className={styles.tableColumn}>Currency Type</th>
              <th className={styles.tableColumn}>Amount</th>
              <th className={styles.tableColumn}>Status</th>
              <th className={styles.tableColumn}>Transaction Hash</th>
              <th className={styles.tableColumn}>Active Since</th>
            </tr>
          </thead>
          <tbody>
            {transactionHistory.length > 0 ? (
              transactionHistory.map((transaction: UserTransactionHistoryTypes) => (
                <tr className={styles.tableBodyRow} key={transaction.id}>
                  <td className={styles.tableColumn}>{transaction.action}</td>
                  <td className={styles.tableColumn}>{transaction.currency}</td>
                  <td className={styles.tableColumn}>{transaction.amount}</td>
                  <td className={styles.tableColumn}>{transaction.status}</td>
                  <td className={styles.tableColumn}>{transaction.transactionHash}</td>
                  <td className={styles.tableColumn}>
                    {new Date(transaction.createdAt).toDateString()},{' '}
                    {new Date(transaction.createdAt).toLocaleTimeString()}
                  </td>
                </tr>
              ))
            ) : (
              <p className="p-2">No transaction record found</p>
            )}
          </tbody>
        </table>
      </div>
      {total > take && <Pagination skip={skip} take={take} total={total} getValue={getValue} />}
    </div>
  );
};

export default UserTransactionHistory;
