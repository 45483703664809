import React, { useState } from 'react';
import './styles.scss';
import StepsView from '../../../components/NewCampaign/StepsView';
import Content from '../../../componentsv2/TTCM/AddCampaign/Content';

const steps = ['Budget', 'Campaign Information', 'Algorithm', 'TTCM Campaign Selection', 'Preview'];

const TTCMAddCampaign: React.FC = () => {
  const firstStep = 0;
  const finalStep = steps.length - 1;
  const [activeStep, setActiveStep] = useState(firstStep);

  const handleNext = () => {
    setActiveStep((prevState) => (prevState < finalStep ? prevState + 1 : prevState));
  };

  const handleBack = () => {
    setActiveStep((prevState) => (prevState > firstStep ? prevState - 1 : prevState));
  };

  const createCampaign = () => {
    setActiveStep((prevState) => (prevState > firstStep ? prevState - 1 : prevState));
  };

  return (
    <div className="w-full px-10 py-5 overflow-scroll">
      <StepsView list={steps} activeStep={activeStep} />
      <div className="w-full">
        <Content
          activeStep={activeStep}
          firstStep={0}
          finalStep={steps.length - 1}
          handleBack={handleBack}
          handleNext={handleNext}
          handleSubmit={createCampaign}
        />
      </div>
    </div>
  );
};

export default TTCMAddCampaign;
