import React, { FC } from 'react';
import './termsAndConditions.scss';

interface ITermsProps {
  agreementChecked: boolean;
  isChecked: (value: boolean) => void;
}

interface IOrderListProps {
  number: string;
  content: string;
}
const OrderList: FC<IOrderListProps> = ({ number, content }) => {
  return (
    <div className="orderList">
      <strong className="listNumber">{number}.</strong>
      <strong>{content}</strong>
    </div>
  );
};

const SubOrderList: FC<IOrderListProps> = ({ number, content }) => {
  return (
    <div className="subOrderList">
      <span className="subListNumber">({number})</span>
      <span>{content}</span>
    </div>
  );
};

const AlphaOrderList: FC<IOrderListProps> = ({ number, content }) => {
  return (
    <div className="alphaOrderList">
      <span>({number})</span>
      <p dangerouslySetInnerHTML={{ __html: content }}></p>
    </div>
  );
};

const TermsAndConditions: FC<ITermsProps> = ({ isChecked, agreementChecked }) => {
  return (
    <div className="termsAndConditions">
      <div className="titleBox">
        <h1>Raiinmaker</h1>
        <h2>Brand Owner Terms of Use and License Agreement</h2>
        <h3>Please Read This Agreement Carefully. This Is a Binding Contract.</h3>
      </div>
      <div className="content">
        <p>
          This Terms of Use and License Agreement (the “License Agreement”) establishes the legal terms under which
          brand owners and brand professionals (referred to as “Brand Owners” or as “you”) may: (1) display your
          proprietary brand related marketing campaign (collectively, “Brand Campaign”) on the Raiinmaker website(s) and
          App(s) (collectively the “Site”), to be viewed and reacted upon by Raiinmaker’s influence Members
          (collectively, “End Users”) of the Site, and (2) authorize influence Members’ rewards (“Member Rewards”) as
          part of your Brand Campaign on the Site.
        </p>
        <p>
          All Raiinmaker Sites and interactive services are owned and operated by Coiin, LLC. d/b/a Raiinmaker
          (“Raiinmaker”, “we”, “us”, or “our”). You grant Raiinmaker the right to grant limited, non-exclusive licenses
          to End Users to view a digital copy of, and to review and react to the Brand Campaign with such influencer’s
          Member contacts, on the terms established by your Brand Campaign on the Site, and by the terms of our separate
          Members Terms of Use Agreement (the “User Agreement”{' '}
          <a href="https://raiinmaker.com/terms" target={'_blank'} rel="noreferrer">
            https://raiinmaker.com/terms
          </a>{' '}
          ) and Privacy Policy (
          <a href="https://raiinmaker.com/privacy" target={'_blank'} rel="noreferrer">
            https://raiinmaker.com/privacy
          </a>
          ) which User Agreement and Privacy Policy are linked here to and incorporated herein by this reference) and to
          collect and share with the Membership Rewards. The User Agreement terms and conditions and our Privacy Policy
          also fully apply to you whether or not you become a Raiinmaker member as a Brand Owner.
        </p>
        <p>
          It is important that you read this License Agreement and indicate your acceptance of all of its terms by
          selecting the “Agreed and Accepted” box at the end of the License Agreement.{' '}
          <strong>
            If you do not accept and agree to be legally bound by these terms, do not select the “Agreed and Accepted”
            box and do not attempt to upload any Brand Campaign to the Site.
          </strong>
        </p>
        <OrderList number="1" content="License for Online Display and Marketing" />
        <p>
          In consideration for the services provided by to you under this License Agreement, including operating an
          online platform for the display of your Brand Campaign for the Members to react to, you agree that with
          respect to all Brand Campaign content that you submit for uploading to and display on the Site, Raiinmaker
          has, and you hereby grant to Raiinmaker, a non-exclusive worldwide right and license, royalty free and
          irrevocable (in connection with each such Brand Campaign once commenced on the Raiinmaker Site) during the
          Term of the License Agreement, to display that Brand Campaign on the Site, and to grant to End Users all
          rights to react to your Brand Campaign as provided by and subject to the your Site Brand Campaign and the
          Member User Agreement. You further grant to Raiinmaker a license to use, reproduce, distribute, redistribute,
          publish, republish, upload, post, transmit, crop, package, repackage, publicly perform and display your Brand
          Campaign content for, and to the extent necessary to accomplish, the purposes of this License Agreement and
          the User Agreement.
        </p>
        <OrderList number="2" content="Brand Owner Status" />
        <AlphaOrderList
          number="a"
          content="In order to participate on the Site as a Brand Owner, in addition to indicating your
          acceptance of this License Agreement, you must complete and submit to Raiinmaker the Brand Campaign
          Application found here <a href='https://seed.raiinmaker.com' target={'_blank'} rel='noreferrer'>https://seed.raiinmaker.com </a> . Raiinmaker reserves the right to accept or reject any
          Brand Campaign Application in its sole discretion."
        />
        <AlphaOrderList
          number="b"
          content="Upon receipt of your Brand Campaign Application, Raiinmaker will notify you when and if the
          application has been approved, normally within 10 business days of Raiinmaker’s receipt of your Brand Campaign
          Application. If your Brand Campaign Application is accepted, your account will be activated, and you will
          receive directions for submitting Brand Campaign content for uploading to the Site and submitting your Brand
          Campaign service fees and Campaign fund. Each upload will be governed by the terms of this License Agreement."
        />
        <OrderList number="3" content="Intellectual Property Representations" />
        <AlphaOrderList number="a" content="By submitting Brand Campaign content, you represent and warrant:" />
        <SubOrderList
          number="1"
          content="That you own all proprietary rights, including copyright, in and to the Brand Campaign & conten."
        />
        <SubOrderList
          number="2"
          content="That you have the full and unrestricted legal right to grant End Users the rights to use and react to your Brand Campaign contemplated by the User Agreement, and that you have not granted any license or entered into any agreement that conflicts, interferes or is inconsistent with the rights and obligations that you are undertaking under this License Agreement."
        />
        <AlphaOrderList
          number="b"
          content="You acknowledge and agree that Raiinmaker prohibits the uploading to or display on the Site of any Brand Campaign content or other material that infringes on any patent, copyright, trademark, trade secret, right to privacy, right to publicity, or any other applicable law or proprietary right of any person."
        />
        <AlphaOrderList
          number="c"
          content="The parties acknowledge and agree that all rights, including title and copyright, in and to the Brand Campaign & content will be retained by the Brand Campaign Owner, and that no title or copyright is transferred or granted to Raiinmaker or to any End User by this License Agreement and/or by the User Agreement."
        />
        <AlphaOrderList
          number="d"
          content="You acknowledge and agree that Raiinmaker reserves the right in its sole discretion to decline to display on the Site, or to remove from the Site, any Brand Campaign content that Raiinmaker determines in the exercise of its subjective discretion is not suitable for display on the Site or otherwise does not conform to the requirements of this License Agreement."
        />
        <AlphaOrderList
          number="e"
          content="The Site contains logos, text, software, graphics, pictures and other material and information that is owned by and proprietary to Raiinmaker, including, without limitation, the “Raiinmaker” and “Coiin” marks and logos (the “Raiinmaker Proprietary Material”). You acknowledge Raiinmaker’s exclusive ownership of the Raiinmaker Proprietary Material and agree not to use or alter in any way any Raiinmaker Proprietary Material without the prior written consent of an authorized officer of Raiinmaker."
        />
        <OrderList number="4" content="Brand Campaign Content Categories" />
        <p>
          Raiinmaker may segregate Brand Campaigns on the Site into categories based upon such variables as:
          Alphabetical, Relevant, Ending Soonest, Newest, etc. Raiinmaker reserves the right to make these category
          decisions in accordance with its own subjective judgment, and its decisions in this regard shall be final.
        </p>
        <OrderList number="5" content="How to Pay for a Brand Campaign as a Brand Owner Member" />
        <p>
          You will pay for each Brand Owner Campaign via your account on the Raiinmaker Brand Portal Administration
          page. You will send the appropriate amount of “Coiin” tokens to your campaign funding address on the App to
          initiate each Brand Campaign.
        </p>
        <OrderList number="6" content="Managing Brand Campaign Content" />
        <AlphaOrderList
          number="a"
          content="You acknowledge and agree that you will be responsible for all access or use of the upload portions of the Site that occurs in conjunction with your Brand Owner membership name and password."
        />
        <AlphaOrderList
          number="b"
          content="Notwithstanding that Raiinmaker reserves the right to decline to post or to remove previously posted Brand Campaign content from the Site that in Raiinmaker’s sole subjective judgment does not satisfy the desired qualitative standards or is otherwise unacceptable, Raiinmaker does not undertake and has no obligation to review all Brand Campaign content uploaded to the Site and is not responsible for the content, quality or consequences of your uploading such Brand Campaign content to the Site. You acknowledge that any screening of Brand Campaign content is done as a courtesy only."
        />
        <AlphaOrderList
          number="c"
          content="You acknowledge that End Users who review and react to your Brand Campaign content pursuant to the User Agreement may violate or exceed the limitations and restrictions on permitted use of that Brand Campaign content. In no event will Raiinmaker be liable to you with respect to any such third party’s violation of your rights in Brand Campaign content. If Raiinmaker obtains actual knowledge of such a material breach of the User Agreement, it may, but will not be obligated to, take reasonable and appropriate action, at its cost, to cause the End User to cease any unauthorized use of the licensed Brand Campaign content and to recover damages or other relief for breach of the User Agreement. In addition, Raiinmaker will cooperate reasonably with you, at your cost, to assist you to enforce your intellectual property rights in Brand Campaign content against an End User who is breaching the User Agreement. In the event that you or Raiinmaker take action against an End User for unauthorized use of Brand Campaign content in violation of the User Agreement, all proceeds of such action (whether by recovery of a money judgment or arbitration award or by settlement), after each party has recouped all of its costs and expenses, including attorneys’ fees, incurred in such proceeding, shall be split evenly between the Brand Owner and Raiinmaker, unless otherwise agreed in writing between Raiinmaker and Brand Owner."
        />
        <OrderList number="7" content="Representations and Warranties" />
        <p>You represent and warrant that:</p>
        <AlphaOrderList
          number="a"
          content="You have the legal capacity and authority to enter into this License Agreement, you are and will be the sole and exclusive owner of all Brand Campaign content that you submit for posting on the Site, and the Brand Campaign content you submit will not infringe any copyright, trademark, right of privacy, right of publicity or other proprietary right of any third party, or defame or cast into disrepute any third party"
        />
        <AlphaOrderList
          number="b"
          content="No portion of the Brand Campaign content that you submit for posting on the Site will contain any disabling mechanism or protection feature designed to prevent its use, copying or enjoyment in the manner contemplated by this License Agreement and the User Agreement, and all such Brand Campaign content will be free of any worm, virus, lock or other mechanism or device that may be used to damage or disable the Site or any Brand Campaign content."
        />
        <OrderList number="8" content="Term and Termination" />
        <AlphaOrderList number="a" content="This License Agreement is effective until terminated." />
        <AlphaOrderList
          number="b"
          content="Either party may terminate this License Agreement with or without cause by giving 10 business days written notice to the other party of its intent to terminate the License Agreement."
        />
        <AlphaOrderList
          number="c"
          content="In addition, Raiinmaker may terminate this License Agreement with a Brand Owner and set off any fees or credits contained in such account against its costs of administration if there has been (i) in the reasonable opinion of Raiinmaker any material misrepresentation made as to the capacity, identity or copyright ownership of Brand Owner, or (ii) no log-in activity or other use of the account by the Brand Owner for 24 months despite commercially reasonable efforts by Raiinmaker to contact the Brand Owner based on the contact information most recently provided to Raiinmaker by that Brand Owner."
        />
        <AlphaOrderList
          number="d"
          content="Upon the termination of this License Agreement, the grant of authority to Raiinmaker with regard to Brand Owner campaign content shall cease, but be subject to the following:"
        />
        <SubOrderList
          number="1"
          content="Raiinmaker will remove all of the involved Brand Owner campaign content normally within 5 business days following termination; and"
        />
        <SubOrderList
          number="2"
          content="Notwithstanding termination, Raiinmaker will have the right to continue licensing that Brand Owner campaign content until it is removed from the Site and will allow the appropriate Member Rewards to be redeemed for such post-termination End User Member accounts."
        />
        <AlphaOrderList
          number="e"
          content="Upon termination, Raiinmaker will be entitled to retain all amounts owing to the Raiinmaker for a period of 30 days to determine any applicable right of set- off, and will be entitled to deduct from such amounts a 15% administrative fee for establishing, managing and terminating the account."
        />
        <AlphaOrderList
          number="f"
          content="The termination of this License Agreement will not, in any event, alter, effect, limit or terminate the rights in particular Brand Owner campaign content granted to an End User pursuant to a User Agreement validly entered into between Raiinmaker and the End User prior to the termination of the License Agreement."
        />
        <AlphaOrderList
          number="g"
          content="Termination of this License Agreement shall be without prejudice to Raiinmaker’s rights, defenses and limitations of liability under this Agreement, and all such rights, defenses and limitations of liability, as well as Raiinmaker’s right of indemnity based on the Brand Owner’s breach of this License Agreement, will survive termination and continue in full force and effect thereafter."
        />
        <OrderList number="9" content="Limitation of Warranties and Representations" />
        <AlphaOrderList
          number="a"
          content='The site, any content contained therein, and all other information presented on the site, is provided "as is" and on an "as available" basis, without warranty of any kind, and Raiinmaker expressly disclaims all warranties, expressed or implied, including but not limited to, the implied warranties of title, merchantability, non- infringement, and fitness for a particular purpose. Raiinmaker does not warrant that the operation of the Site will be uninterrupted, error free or without breaches of security. Raiinmaker makes no representation or warranties about the accuracy, reliability, completeness, currency or timeliness of the content, software, text, graphics, links, or communication provided on or through the use of the Site. It does not guarantee the Site to be error-free, secure, continuously available or free of viruses or other harmful components.'
        />
        <p>
          <i>
            Some jurisdictions do not allow the exclusion or limitation of implied warranties, so in these jurisdictions
            the above limitations or exclusions may not apply or may be limited.
          </i>
        </p>
        <OrderList number="10" content="Limitation of Liability." />
        <AlphaOrderList
          number="a"
          content="In no event will Raiinmaker, its officers, directors, employees, or subsidiaries, be liable to you or any party for any incidental, consequential, special, indirect or punitive damages (including damages for loss of profits, interruption or loss of business information, or any other pecuniary loss) arising out of or in connection with your use of the Site or of the services offered by the Site, the availability or unavailability of the Site or of Brand Campaign content, or any other services or goods obtained through use of any of the foregoing, or any data transmitted through the Site, however arising, including negligence, even if Raiinmaker has been advised of the possibility of such damages; and irrespective whether the claim is based on contract, tort (including negligence), infringement of intellectual property or otherwise."
        />
        <AlphaOrderList
          number="b"
          content="Without limiting the foregoing, in no case will the liability of Raiinmaker or any of the Related Parties exceed the amount that you paid to us or our designees during the 6 months prior to the time the cause of action giving rise to liability arose or $500, whichever amount is less."
        />
        <AlphaOrderList
          number="c"
          content="Remedies under this agreement are exclusive and are limited to those expressly provided for in this agreement. Raiinmaker will not be responsible or liable, under any circumstances, for any loss, liability, expenses or damages incurred in connection with the Site and/or the Service to the extent caused by or resulting from: (a) your acts, defaults or omissions; (b) your violation of any of any term, condition, representation or warranty contained in this TOU; or (c) acts or omissions of third parties, including third parties contracted by Raiinmaker."
        />
        <p>
          <i>
            Some jurisdictions do not allow the exclusion or limitation of certain damages, so in these jurisdictions
            the above limitations or exclusions may not apply to you.
          </i>
        </p>
        <OrderList number="11" content="Indemnification" />
        <p>
          You agree to indemnify, defend and hold Raiinmaker, its affiliates, its influence Members, and their
          respective employees, officers, directors, agents and representatives (collectively, the “Raiinmaker Parties”)
          harmless from and against any losses, costs, liabilities and expenses (including reasonable attorneys&apos;
          fees) incurred by any Raiinmaker Party as a result of or in connection with any breach by you or anyone acting
          on your behalf of any of the terms of this License Agreement.
        </p>
        <OrderList number="12" content="General Provisions" />
        <AlphaOrderList
          number="a"
          content="You acknowledge and affirm that you have, in addition to the terms of this License Agreement, carefully reviewed the terms of the User Agreement, our Privacy Policy and any other agreement that may be incorporated therein, and to the extent that the terms of such other agreements are expressly referred to in or control this License Agreement, you agree to those other terms as well."
        />
        <AlphaOrderList
          number="b"
          content="This License Agreement is personal to you and is not assignable by you without the prior written approval of Raiinmaker. Raiinmaker may assign this Agreement without your consent to any other party that agrees to be bound by its terms."
        />
        <AlphaOrderList
          number="c"
          content="<ins>Governing Law; Jurisdiction.</ins> This License Agreement is governed by, and will be construed under, the laws of the United States of America and the law of the State of Wyoming, without regard to conflict of law principles. The application of the United Nations Convention on Contracts for the International Sale of Goods is expressly excluded. Except as expressly provided below (and claims proceeding in any small claims court), all disputes arising out of or related to your use of the Sites and/or the Service shall be subject to binding arbitration in English, as provided in paragraph 12 below. To the extent any matter is excluded from arbitration as set forth below, all such disputes, shall be subject to the exclusive jurisdiction of the state and federal courts located within Cheyenne, Wyoming, and you agree to submit to the personal jurisdiction and venue of such courts. <strong>You and Raiinmaker agree that any cause of action arising out of or related to the Sites or Services must commence within 1 year after the cause of action accrues. Otherwise, such cause of action is permanently barred.</strong> "
        />
        <AlphaOrderList
          number="e"
          content="The failure of Raiinmaker to insist upon or enforce strict performance of any provision of this Agreement, or to exercise any right under this Agreement, will not be construed as a waiver or relinquishment of the right of Raiinmaker to rely upon or demand performance of that provision on any other occasion."
        />
        <AlphaOrderList
          number="f"
          content="<strong>Raiinmaker reserves the right to modify or amend the provisions of this License Agreement from time to time, and it shall prominently post notice of any such modified or amended terms on the Site.</strong> You shall be bound by any such modified or amended terms if you use or access the Site after such posting unless you give written notice to Raiinmaker within 30 days following such notice of amended terms that you have decided to terminate this License Agreement in accordance with the Termination section above."
        />
        <AlphaOrderList
          number="g"
          content='The Site may contain links to websites operated by parties other than Raiinmaker ("Third Party Site "). Raiinmaker’s links to such Third-Party Sites do not imply any endorsement of the products, services or material on such Third- Party Site or any association with their sponsors or operators. Raiinmaker expressly disavows any responsibility or liability for any content, services and products available through all such Third-Party Sites. Linking to any other service or site from this Site is at each Brand Owner’s own risk.'
        />
        <AlphaOrderList
          number="h"
          content="No agency, partnership, joint venture, employee-employer or franchiser- franchisee relationship is intended or created between you and Raiinmaker. Nothing in this Agreement is intended to create, or shall be construed as creating, a taxable entity between the parties to this License Agreement, or any right to pledge the other’s credit, it being understood that Raiinmaker and you are independent contractors vis-à-vis one another."
        />
        <OrderList number="13" content="Binding Arbitration." />
        <AlphaOrderList
          number="a"
          content="<ins>Arbitration Procedures.</ins> If you are a Raiinmaker member in the United States (including its possessions and territories), you and Raiinmaker agree that any dispute, claim or controversy arising out of or relating in any way to the Raiinmaker Service, this License Agreement, the User Agreement and this Arbitration Agreement, shall be determined by binding arbitration or in small claims court. Arbitration is more informal than a lawsuit in court. Arbitration uses a neutral arbitrator instead of a judge or jury, allows for more limited discovery than in court, and is subject to very limited review by courts. Arbitrators can award the same damages and relief that a court can award and nothing in this Arbitration Agreement shall be interpreted as limiting any non-waivable statutory rights. You agree that, by agreeing to these this License Agreement and User Agreement, the U.S. Federal Arbitration Act governs the interpretation and enforcement of this provision, and that you and Raiinmaker are each waiving the right to a trial by jury or to participate in a class action. This arbitration provision shall survive termination of this Agreement and the termination of your Raiinmaker membership."
        />
        <AlphaOrderList
          number="b"
          content='If you elect to seek arbitration or file a small claim court action, you must first send to Raiinmaker, by certified mail, a written Notice of your claim ("Notice"). The Notice to Raiinmaker must be addressed to: Legal Department, Coiin LLC, Inc., 30 N. Gould St. Sheridan, Wyoming 82801 USA ("Notice Address"). If Raiinmaker initiates arbitration, it will send a written Notice to the email address used for your membership account. A Notice, whether sent by you or by Raiinmaker, must (a) describe the nature and basis of the claim or dispute; and (b) set forth the specific relief sought ("Demand"). If Raiinmaker and you do not reach an agreement to resolve the claim within 30 days after the Notice is received, you or Raiinmaker may commence an arbitration proceeding or file a claim in small claims court. You may download or copy a form Notice and a form to initiate arbitration at <a href="www.adr.org" target={"_blank"} rel="noreferrer">www.adr.org.</a> '
        />
        <AlphaOrderList
          number="c"
          content="If your claim is for US$10,000 or less, we agree that you may choose whether the arbitration will be conducted solely on the basis of documents submitted to the arbitrator, through a telephonic hearing, or by an in-person hearing as established by the AAA Rules. If your claim exceeds US$10,000, the right to a hearing will be determined by the AAA Rules. Regardless of the manner in which the arbitration is conducted, the arbitrator will issue a reasoned written decision explaining the essential findings and conclusions on which the award is based."
        />
        <AlphaOrderList
          number="d"
          content="<strong>You and Raiinmaker agree that each may bring claims against the other only in your or its individual capacity, and not as a plaintiff or class member in any purported class or representative proceeding.</strong> Further, unless both you and Raiinmaker agree otherwise, the arbitrator may not consolidate more than one person's claims with your claims and may not otherwise preside over any form of a representative or class proceeding. If this specific provision is found to be unenforceable, then the entirety of this arbitration provision shall be null and void. <p><strong>By agreeing to this arbitration provision, you understand that you and Raiinmaker waive the right to sue in court and have a jury trial.</strong></p>"
        />
        <OrderList number="14" content="Contact" />
        <p>
          If you have any questions about this License Agreement or wish to terminate the Agreement as provided in the
          Termination section above, please contact Raiinmaker at:{' '}
          <a href="support@raiinmaker.com" target={'_blank'} rel="noreferrer">
            support@raiinmaker.com
          </a>
          .
        </p>
        <OrderList number="15" content="Acknowledgement" />
        <p>
          By selecting and clicking on the “Agreed and Accepted” box at the bottom of this page, you acknowledge that
          you have read this Agreement, understand it, and have had the opportunity to seek independent legal advice
          prior to agreeing to it. In consideration of Raiinmaker agreeing to allow the posting of your Brand Owner
          Campaign content for possible review and response by End Users, you agree to be bound by the terms and
          conditions of this License Agreement. You further agree that this License Agreement constitutes the complete
          and exclusive statement of the agreement between you and Raiinmaker concerning the subject matter hereof, and
          that this License Agreement supersedes any proposal or prior agreement, oral or written, or any other
          communication between you and Raiinmaker relating to the subject matter of this License Agreement.
        </p>
      </div>
      <div className="termsAndConditions">
        <input type={'checkbox'} checked={agreementChecked} onClick={() => isChecked(!agreementChecked)} />
        <p>
          <strong>ACCEPTED AND AGREED</strong>
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
