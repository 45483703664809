import React, { FC } from 'react';
import raiinmakerLogo from '../../assets/svg/raiinmaker.svg';
import Divider from '../Divider';
import './sidebar.scss';
import { useHistory } from 'react-router-dom';
import useStoreUserSelector from '../../hooks/useStoreUserSelector';
import { getRoutesMapping } from '../../helpers/routesMapping';
import dashboardIcon from '../../assets/svg/sidebar/dashbord.svg';
import campaignIcon from '../../assets/svg/sidebar/campaign.svg';
import customerIcon from '../../assets/svg/sidebar/customer.svg';
import cryptoIcon from '../../assets/svg/sidebar/cryptoIcon.svg';
import newCampaignIcon from '../../assets/svg/sidebar/plusIcon.svg';
import adminIcon from '../../assets/svg/sidebar/adminIcon.svg';
import settingIcon from '../../assets/svg/sidebar/settingIcon.svg';
import faqIcon from '../../assets/svg/sidebar/faqIcon.svg';
import supportIcon from '../../assets/svg/sidebar/supportIcon.svg';
import coiinIcon from '../../assets/svg/sidebar/coinSend.svg';
import listIcon from '../../assets/svg/sidebar/list.svg';
import clipboardIcon from '../../assets/svg/sidebar/clipboard.svg';
import stepsIcon from '../../assets/svg/sidebar/steps.svg';
import useStoreSettingsSelector from '../../hooks/useStoreSettingsSelector';
import useStoreProfileSelector from '../../hooks/useStoreProfileSelector';

interface Map {
  [key: string]: string | undefined;
}

const SidebarIcons: Map = {
  dashboard: dashboardIcon,
  campaign: campaignIcon,
  newCampaign: newCampaignIcon,
  crypto: cryptoIcon,
  admin: adminIcon,
  user: customerIcon,
  management: clipboardIcon,
  walkthrough: stepsIcon,
  list: listIcon,
  setting: settingIcon,
  faq: faqIcon,
  support: supportIcon,
  credits: coiinIcon,
};

const Sidebar: FC = () => {
  const { imageUrl } = useStoreProfileSelector();
  const { push } = useHistory();
  const { company } = useStoreUserSelector();
  const { ttcm } = useStoreSettingsSelector();
  const menuList = getRoutesMapping({ company, ttcm });
  const { location } = useHistory();

  return (
    <div className="sidebar">
      <div className="imageWrapper">
        <img src={imageUrl || raiinmakerLogo} alt="raiinmaker logo" onClick={() => push('/dashboard')} />
      </div>
      <div className="dividerWrapper">
        <Divider className="divider" />
      </div>
      <p className="title">Menu</p>
      {menuList &&
        menuList.map((item) => {
          if (!item.enabled) return;
          return (
            <div key={item.name} className="menuWrapper">
              <div
                className={`itemsWrapper ${item.to === location.pathname ? 'activeTab' : ''}`}
                onClick={() => push(item.to)}
              >
                <img src={SidebarIcons?.[item.icon]} alt="raiinmaker dashboard" />
                <p>{item.name}</p>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Sidebar;
