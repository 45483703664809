import { useSelector } from 'react-redux';
import { RootStoreState } from '../store/reducers';
import { TTCMCampaignState } from '../types';

const useStoreTTCMCampaignListSelector = (): TTCMCampaignState[] => {
  const campaign = (state: RootStoreState) => state.ttcmCampaignList;
  return useSelector(campaign);
};

export default useStoreTTCMCampaignListSelector;
