import React from 'react';
import CustomButton from '../CustomButton/CustomButton';
import { useHistory } from 'react-router';
import useStoreSettingsSelector from '../../hooks/useStoreSettingsSelector';

const EmptyCampaigns: React.FC = () => {
  const history = useHistory();
  const { ttcm } = useStoreSettingsSelector();

  return (
    <div className="flex flex-col justify-center items-center w-full h-full">
      <p className="text-2xl mb-2">No Campaigns Found</p>
      <CustomButton
        className="w-60 bg-cyberYellow h-12 rounded text-lg text-black"
        variant="outlined"
        color="primary"
        onClick={() => {
          history.push(ttcm ? '/ttcm/addCampaign' : '/dashboard/newCampaign');
        }}
      >
        Create your first campaign
      </CustomButton>
    </div>
  );
};

export default EmptyCampaigns;
