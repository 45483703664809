import React from 'react';
import EmptyCampaigns from '../../../components/Campaigns/EmptyCampaigns';
import './styles.scss';
import useStoreTTCMCampaignListSelector from '../../../hooks/useStoreTTCMCampaignListSelector';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch } from 'react-redux';
import { removeTTCMCampaign } from '../../../store/actions/ttcmCampaignList';

const CampaignsTable: React.FC = () => {
  const campaigns = useStoreTTCMCampaignListSelector();
  const dispatch = useDispatch();

  const removeCampaign = (name: string) => {
    dispatch(removeTTCMCampaign(name));
  };

  return (
    <div>
      {!campaigns.length ? (
        <EmptyCampaigns />
      ) : (
        <div className="tableWrapper">
          <table>
            <thead>
              <tr className="tableRow">
                <th>Campaign Name</th>
                <th>Currency</th>
                <th>Budget</th>
                <th>Status</th>
                <th>Audit Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {campaigns.map((campaign) => (
                <tr key={campaign.name} className="hover:bg-gray-100 border-b-2 border-solid border-gray-100">
                  <td className="px-7 py-5 text-left capitalize">{campaign.name}</td>
                  <td className="px-7 py-5 text-left">{campaign.currency.split('-')[0]}</td>
                  <td className="px-7 py-5 text-left">{campaign.budget}</td>
                  <td className="px-7 py-5 text-left">APPROVED</td>
                  <td className="px-7 py-5 text-left">DEFAULT</td>
                  <td className="px-7 py-5 text-left">
                    <DeleteIcon
                      className="cursor-pointer"
                      onClick={() => {
                        removeCampaign(campaign.name);
                      }}
                      fontSize="small"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default CampaignsTable;
