import React, { useState, FC, Fragment } from 'react';
import './credits.scss';
import CustomButton from '../../components/CustomButton';
import { ApiClient } from '../../services/apiClient';
import { BallTriangle } from 'react-loader-spinner';
import checkIcon from '../../assets/svg/sidebar/check-circle.svg';
import errorIcon from '../../assets/svg/sidebar/error.svg';

interface FormProps {
  onSubmit: (data: FormData) => void;
}

interface FormData {
  username: string;
  creditsAmount: number;
}

const Credits: FC = () => {
  const [formData, setFormData] = useState<FormData>({
    username: '',
    creditsAmount: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [sentRequest, setSentRequest] = useState(false);
  const [statusSuccess, setStatusSuccess] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    setIsLoading(true);
    event.preventDefault();
    ApiClient.updateCredits(formData).then((status) => {
      setIsLoading(false);
      setSentRequest(true);
      setStatusSuccess(status);
    });
  };

  const repeatProcess = () => {
    setSentRequest(false);
    setStatusSuccess(false);
  };

  return (
    <>
      {!sentRequest && !isLoading && (
        <form className="creditsForm" onSubmit={handleSubmit}>
          <h1 className="creditsTitle">
            <b>Give Credits to Users</b>
          </h1>
          <div>
            <label className="creditsLabel" htmlFor="username">
              Username:
            </label>
            <input
              type="text"
              id="username"
              name="username"
              className="creditsInput"
              placeholder="input username"
              value={formData.username}
              onChange={handleInputChange}
              required
            />
          </div>
          <div>
            <label className="creditsLabel" htmlFor="creditsAmount">
              Credits Amount:
            </label>
            <input
              type="number"
              id="creditsAmount"
              name="creditsAmount"
              className="creditsInput"
              value={formData.creditsAmount}
              onChange={handleInputChange}
              step={1}
              required
            />
          </div>

          <div className="buttonWrapperCredits">
            <CustomButton className="giveCreditsButton" type="submit">
              Update Credits
            </CustomButton>
          </div>
        </form>
      )}
      {isLoading && (
        <div className="creditsSpinner">
          <BallTriangle color="#3a6ff8" />
          <h1 className="spinnerText">Updating Credits...</h1>
        </div>
      )}
      {sentRequest && !isLoading && statusSuccess && (
        <div className="divSuccessError">
          <img src={checkIcon} className="successImage" />
          <span className="text-sm my-4">Credits updated successfully!</span>

          <div className="buttonWrapperCredits">
            <CustomButton className="giveCreditsButton" onClick={repeatProcess}>
              Give More Credits
            </CustomButton>
          </div>
        </div>
      )}
      {sentRequest && !isLoading && !statusSuccess && (
        <div className="divSuccessError">
          <img src={errorIcon} className="errorImage" />
          <span className="text-sm my-4">An Error Occurred</span>
          <span className="text-sm text-center">Something went wrong</span>

          <div className="buttonWrapperCredits">
            <CustomButton className="giveCreditsButton" onClick={repeatProcess}>
              Try Again
            </CustomButton>
          </div>
        </div>
      )}
    </>
  );
};

export default Credits;
