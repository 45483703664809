import { MediaInstructionTypes } from '../types';

export const MAX_TAGS_LENGTH = 50;
export const ADMIN_PANEL_TITLE = 'Raiinmaker Admin Panel';
export const ADMIN_PANEL_TAGLINE =
  'Your personal Dashboard to manage your Campaigns and get Insights through various Data Metrics.';

export const RAIINMAKER = 'raiinmaker';
export const TTCM = 'ttcm';
export const APPROVED = 'APPROVED';

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const mediaInstructions: MediaInstructionTypes = {
  TIKTOK:
    'Aspect ratio: 9:16 or 1:1 with sidebars, Recommended dimensions: 1080 x 1920 pixels, Video orientation: vertical, Maximum file size: 20 MB.',
  TWITTER: 'Aspect ratio: 16:9, Recommended dimensions: 1200 x 675 pixels, Maximum file size: 20 MB.',
  FACEBOOK:
    'Aspect ratio: 1.91:1 (Horizontal), 1:1 (Square), 4:5 (Vertical), Recommended dimensions: 2048 x 1149 pixels (Horizontal),  2048 x 2048 pixels (Square), 2048 x 3072 pixels (Portrait), Maximum file size: 20 MB.',
  INSTAGRAM:
    'Aspect ratio: 16:9 (Horizontal), 1:1 (Square), 4:5 (Vertical), Recommended dimensions: 1080 x 608 pixels (Horizontal),  1080 x 1080 pixels (Square), 1080 x 1350 pixels (Portrait), Maximum file size: 20 MB.',
};

export const brandManual = 'https://docs.google.com/document/d/1y7K89yUtLZgUr-ig79UP6YlOCnKjapEr6ngezMhBjC0/edit';
export const generalFaqs = 'https://raiinmaker.zendesk.com/hc/en-us/sections/5704814468628-FAQ';

export const CAMPAIGN = 'CAMPAIGN';
export const TWITTER = 'TWITTER';
export const INSTAGRAM = 'INSTAGRAM';
export const FACEBOOK = 'FACEBOOK';
export const TIKTOK = 'TIKTOK';
export const AI_GENERATIVE = 'AI_GENERATIVE';

export enum SOCIAL_CHANNEL {
  TWITTER = 'TWITTER',
  INSTAGRAM = 'INSTAGRAM',
  FACEBOOK = 'FACEBOOK',
  TIKTOK = 'TIKTOK',
}

export const MAX_POST_LENGTH = 120;
export const COIIN = 'COIIN';
export const BSC = 'BSC';
export const CAMPAIGN_NAME_EXISTS = 'CAMPAIGN_NAME_EXISTS';
export const FUNDINGWALLET = 'FundingWallet';
export const CRYPTOS = 'Cryptos';
export const USER_NOT_FOUND = 'User not found';

export enum CampaignAuditStatus {
  DEFAULT = 'DEFAULT',
  PENDING = 'PENDING',
  AUDITED = 'AUDITED',
}

export enum APPROVAL_STATUS {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export const TIKTOK_AUTH_URL =
  'https://ads.tiktok.com/marketing_api/auth?app_id=7217771289254035457&state=your_custom_params&redirect_uri=https%3A%2F%2Fseed-staging.raiinmaker.com%2Fdashboard%2Ftiktok&rid=4ykpilltqul';

export enum AI_MODEL_TYPES {
  GENERAL = 'GENERAL',
  BUILDINGS = 'BUILDINGS',
  CHARACTERS = 'CHARACTERS',
  ENVIRONMENTS = 'ENVIRONMENTS',
  FASHION = 'FASHION',
  ILLUSTRATIONS = 'ILLUSTRATIONS',
  GAME_ITEMS = 'GAME_ITEMS',
  GRAPHICAL_ELEMENTS = 'GRAPHICAL_ELEMENTS',
  PHOTOGRAPHY = 'PHOTOGRAPHY',
  PIXEL_ART = 'PIXEL_ART',
  PRODUCT_DESIGN = 'PRODUCT_DESIGN',
  TEXTIRES = 'TEXTIRES',
  UI_ELEMENTS = 'UI_ELEMENTS',
  VECTOR = 'VECTOR',
}

export enum KYC_LEVEL {
  LEVEL1 = 'LEVEL1',
  LEVEL2 = 'LEVEL2',
}
