import { PayloadAction } from '@reduxjs/toolkit';
import { TTCMCampaignState } from '../../types';

export const ADD_TTCM_CAMPAIGN = 'ADD_TTCM_CAMPAIGN';
export const REMOVE_TTCM_CAMPAIGN = 'REMOVE_TTCM_CAMPAIGN';

export const addTTCMCampaign = (data: TTCMCampaignState): PayloadAction<TTCMCampaignState> => {
  return {
    type: ADD_TTCM_CAMPAIGN,
    payload: data,
  };
};

export const removeTTCMCampaign = (name: string): PayloadAction<string> => {
  return {
    type: REMOVE_TTCM_CAMPAIGN,
    payload: name,
  };
};
