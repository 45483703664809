import React, { FC, useEffect, useState } from 'react';
import { Box, Checkbox, CircularProgress, FormControlLabel } from '@material-ui/core';
import { AIModel } from '../../../types';
import { ApiClient } from '../../../services/apiClient';

interface Props {
  updateModelData: (data: string) => void;
  imageToImage: boolean;
  setImageToImage: (val: boolean) => void;
  selectedModels: string[];
}

const AIModels: FC<Props> = ({ updateModelData, imageToImage, setImageToImage, selectedModels }) => {
  const [models, setModels] = useState<AIModel[]>([]);
  const [loading, setLoading] = useState(false);

  console.log('adfd', selectedModels);

  useEffect(() => {
    console.log('fetching');
    const fetchData = async () => {
      setLoading(true);
      const data = await ApiClient.getAIModels();
      setModels(data);
      data.forEach((item) => {
        if (selectedModels.includes(item.id)) console.log('foound');
      });
      setLoading(false);
    };
    fetchData();
  }, []);

  if (loading) {
    return (
      <Box className="flex flex-row justify-center align-middle h-60">
        <CircularProgress size={30} color="primary" className="mt-3" />
      </Box>
    );
  }

  return (
    <Box className="aiModelsWrapper">
      <h3 className="modelListLabel">Select an AI Model to use for this campaign.</h3>
      <Box className="imagesContainer">
        {models.map((item) => (
          <Box
            key={item.id}
            onClick={() => updateModelData(item.id)}
            className={`imageBox ${selectedModels.includes(item.id) ? 'selectedBox' : ''}`}
          >
            <img src={item.thumbnail} alt={item.name} className="image" />
            <h3 className="modelTitle">{item.name}</h3>
          </Box>
        ))}
      </Box>
      <Box className="checkBox mt-5">
        <FormControlLabel
          control={
            <Checkbox
              checked={imageToImage}
              style={{ color: '#3f51b5' }}
              name="Brand Agreement"
              onChange={(e, checked) => {
                setImageToImage(checked);
              }}
            />
          }
          label="Enable Image to Image generations for this campaign?"
        />
      </Box>
    </Box>
  );
};

export default AIModels;
