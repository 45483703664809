import React, { FC } from 'react';
import './campaignPostForm.scss';
import { ChannelTemplateObject } from '../../../types';
import CustomButton from '../../CustomButton';
import { MdAdd } from 'react-icons/md';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { MAX_POST_LENGTH, SOCIAL_CHANNEL } from '../../../helpers/constants';
import { IoIosCopy } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { showSuccessAlert } from '../../../store/actions/alerts';

interface TemplateStepsIProps {
  channelTemplates: ChannelTemplateObject[];
  addPost: (channel: SOCIAL_CHANNEL) => void;
  handlePostChange: (params: { channel: SOCIAL_CHANNEL; index: number; data: string }) => void;
  removePost: (channel: SOCIAL_CHANNEL, index: number) => void;
  socialIcon: string;
  phoneImage: string;
  platform: SOCIAL_CHANNEL;
}

const CampaignTemplate: FC<TemplateStepsIProps> = ({
  channelTemplates,
  handlePostChange,
  addPost,
  removePost,
  socialIcon,
  phoneImage,
  platform,
}) => {
  const dispatch = useDispatch();

  return (
    <div className="campaignTemplateWrapper">
      <div className="templateGrid">
        <div className="imageSection">
          <img src={socialIcon} alt="social icon" />
          <div className="phoneImage">
            <img src={phoneImage} />
          </div>
          <p>User Post Templates</p>
        </div>
        <div className="contentSection">
          <div className="addButtonWrapper">
            <CustomButton className="addTempButton" onClick={() => addPost(platform)}>
              <MdAdd className="mr-2" />
              <span>Add Template</span>
            </CustomButton>
          </div>
          {channelTemplates.map((x, i) => (
            <div className="templateFieldWrapper" key={i}>
              <div className="textAreaWrapper">
                <textarea
                  placeholder={`User Post Template ${i + 1}*`}
                  onChange={(e) => handlePostChange({ channel: x.channel, index: i, data: e.target.value })}
                  value={x.post}
                />
                <IoIosCopy
                  className="copyIcon"
                  onClick={() => {
                    if (x.post.length) {
                      navigator.clipboard.writeText(x.post);
                      dispatch(showSuccessAlert('Copied!'));
                    }
                  }}
                />
              </div>
              <div className="templateContentWrapper">
                {i >= 2 ? (
                  <CustomButton className="remove" onClick={() => removePost(x.channel, i)}>
                    <IoCloseCircleOutline className="closeIcon" />
                    <span>Remove Post</span>
                  </CustomButton>
                ) : (
                  <p></p>
                )}
                <p>
                  Characters added {x.post.length}/{MAX_POST_LENGTH}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CampaignTemplate;
