import initialState from '../initialState';
import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { TTCMCampaignState } from '../../types';
import { UPDATE_TTCM_CAMPAIGN, RESET_TTCM_CAMPAIGN } from '../actions/ttcmCampaign';

const ttcmCampaign = createReducer(initialState.ttcmCampaign, {
  [UPDATE_TTCM_CAMPAIGN]: (state, action: PayloadAction<TTCMCampaignState>) => action.payload,
  [RESET_TTCM_CAMPAIGN]: () => initialState.ttcmCampaign,
});

export default ttcmCampaign;
