import React from 'react';
import BudgetForm from './BudgetForm';
import { TTCMCampaignState } from '../../../types';
import InfoForm from './InforForm';
import PayoutValuesForm from './PayoutValuesForm';
import CampaignSelectionForm from './CampaignSelectionForm';
import Preview from './Preview';

export interface TTCMActionsProps {
  activeStep: number;
  firstStep: number;
  finalStep: number;
  handleNext: () => void;
  handleBack: () => void;
  handleSubmit?: (data: TTCMCampaignState) => void;
}

const Content: React.FC<TTCMActionsProps> = ({
  activeStep,
  firstStep,
  finalStep,
  handleBack,
  handleNext,
  handleSubmit,
}) => {
  switch (activeStep) {
    case 0:
      return (
        <BudgetForm
          activeStep={activeStep}
          firstStep={firstStep}
          finalStep={finalStep}
          handleBack={handleBack}
          handleNext={handleNext}
        />
      );
    case 1:
      return (
        <InfoForm
          activeStep={activeStep}
          firstStep={firstStep}
          finalStep={finalStep}
          handleBack={handleBack}
          handleNext={handleNext}
        />
      );
    case 2:
      return (
        <PayoutValuesForm
          activeStep={activeStep}
          firstStep={firstStep}
          finalStep={finalStep}
          handleBack={handleBack}
          handleNext={handleNext}
        />
      );
    case 3:
      return (
        <CampaignSelectionForm
          activeStep={activeStep}
          firstStep={firstStep}
          finalStep={finalStep}
          handleBack={handleBack}
          handleNext={handleNext}
        />
      );
    case 4:
      return (
        <Preview
          activeStep={activeStep}
          firstStep={firstStep}
          finalStep={finalStep}
          handleBack={handleBack}
          handleNext={handleNext}
          handleSubmit={handleSubmit}
        />
      );
    default:
      return (
        <BudgetForm
          activeStep={activeStep}
          firstStep={firstStep}
          finalStep={finalStep}
          handleBack={handleBack}
          handleNext={handleNext}
        />
      );
  }
};

export default Content;
