import React, { useState, useEffect } from 'react';
import { CircularProgress } from '@material-ui/core';
import styles from './userList.module.css';
import headingStyles from '../../assets/styles/heading.module.css';
import CustomButton from '../CustomButton';
import buttonStyles from '../../assets/styles/customButton.module.css';
import { useHistory } from 'react-router-dom';
import { UserListType } from '../../types';
import Pagination from '../Pagination/Pagination';
import fileDownload from 'js-file-download';
import { ApiClient } from '../../services/apiClient';
import { useDispatch } from 'react-redux';
import { showErrorAlert } from '../../store/actions/alerts';

const UserList: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [userList, setUserList] = useState<UserListType[]>([]);
  const [skip, setSkip] = useState<number>(0);
  const [take] = useState<number>(10);
  const [total, setTotal] = useState<number>(0);
  const [filter, setFilter] = useState<string>('');
  const [searchData, setSearchData] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);

  useEffect(() => {
    if (filter) {
      setSearchLoading(true);
      setSkip(0);
    } else {
      setLoading(true);
    }
    ApiClient.getUserRecord({ skip, take, filter })
      .then((response) => {
        setUserList(response.items);
        filter ? setTotal(response.items.length) : setTotal(response.total);
      })
      .catch((error) => dispatch(showErrorAlert((error as Error).message)))
      .finally(() => {
        setLoading(false);
        setSearchLoading(false);
      });
  }, [filter, skip]);

  // Search field
  const handleSearchField = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchData(e.target.value);
    if (e.target.value === '') {
      setFilter('');
    }
  };
  // Handle Key Press
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setFilter(searchData);
    }
  };

  // Search record
  const handleSearchRecord = () => {
    setFilter(searchData);
  };

  // Download file
  const downloadRecord = () => {
    setDownloadLoading(true);
    ApiClient.downloadUserRecord()
      .then((response: any) => {
        fileDownload(response, 'users.csv');
      })
      .catch((error) => dispatch(showErrorAlert((error as Error).message)))
      .finally(() => setDownloadLoading(false));
  };

  // Take paginated value from Pagination component
  const getValue = (skip: number) => {
    setSkip(skip);
  };

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <div className={styles.brandListWrapper}>
        <div className={styles.headingWithSearch}>
          <h1 className={headingStyles.headingXl}>Users Record</h1>
          <div className={styles.searchWrapper}>
            <CustomButton className={buttonStyles.buttonPrimary} onClick={downloadRecord} loading={downloadLoading}>
              Download
            </CustomButton>
            <input
              type="text"
              name="search"
              value={searchData}
              className={styles.inputField}
              placeholder="Search by username or email"
              onChange={handleSearchField}
              onKeyPress={handleKeyPress}
            />
            <CustomButton className={buttonStyles.buttonPrimary} onClick={handleSearchRecord} loading={searchLoading}>
              Search
            </CustomButton>
          </div>
        </div>
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>
              <tr className={styles.tableHeadRow}>
                <th className={styles.tableColumn}>UserName</th>
                <th className={styles.tableColumn}>Email</th>
                <th className={styles.tableColumn}>Twitter Username</th>
                <th className={styles.tableColumn}>Kyc Status</th>
                <th className={styles.tableColumn}>Active Since</th>
              </tr>
            </thead>
            <tbody>
              {userList &&
                userList.map((user: UserListType) => (
                  <tr
                    className={styles.tableBodyRow}
                    key={user.id}
                    onClick={() => history.push(`/dashboard/admin/userDetails/${user.id}`)}
                  >
                    <td className={styles.tableColumn}>{user?.profile?.username}</td>
                    <td className={styles.tableColumn}>{user.email}</td>
                    <td className={styles.tableColumn}>
                      {(user.social_link.length && user.social_link[0].username) || ''}
                    </td>
                    <td className={styles.tableColumn}>{user.kycStatus}</td>
                    <td className={styles.tableColumn}>{new Date(user.createdAt).toDateString()}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <Pagination total={total} skip={skip} take={take} getValue={getValue} />
    </div>
  );
};

export default UserList;
