import React, { FC } from 'react';
import './style.scss';
import useStoreSettingsSelector from '../../hooks/useStoreSettingsSelector';
import { useDispatch } from 'react-redux';
import { toggleTTCM } from '../../store/actions/settings';
import { RAIINMAKER, TTCM } from '../../helpers/constants';

const PortalToggle: FC = () => {
  const dispatch = useDispatch();
  const settings = useStoreSettingsSelector();

  const toggle = (e: React.MouseEvent<HTMLSpanElement>) => {
    const id = e.currentTarget.id;
    dispatch(toggleTTCM(id == 'raiinmaker' ? false : true));
  };

  return (
    <div className="marketplaceToggle">
      <span className={`toggleButton ${!settings.ttcm ? 'active' : ''}`} onClick={toggle} id="raiinmaker">
        {RAIINMAKER}
      </span>
      <span className={`toggleButton ${settings.ttcm ? 'active' : ''}`} onClick={toggle} id="ttcm">
        {TTCM}
      </span>
    </div>
  );
};

export default PortalToggle;
