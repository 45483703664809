import {
  CampaignMediaResponse,
  CampaignTemplateResponse,
  ChannelMediaObject,
  ChannelMediaStructure,
  ChannelTemplateObject,
  ChannelTemplateStructure,
  FileObject,
} from '../types';
import axios from 'axios';
import { dataURLtoFile } from './fileHandler';
import { flatten } from 'lodash';
import { s3ProdMediaUrl, s3StagingMediaUrl } from './affiliateURLs';
import { SOCIAL_CHANNEL } from './constants';
import { Metrics, PointValues } from './mockData';
import { format } from 'date-fns';

const assetUrl = process.env.REACT_APP_STAGE === 'production' ? s3ProdMediaUrl : s3StagingMediaUrl;

export const generateRandomId = (): string => {
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const stringLength = 20;
  function pickRandom() {
    return possible[Math.floor(Math.random() * possible.length)];
  }
  return [...Array(stringLength)].map(pickRandom).join('');
};

export const generateCampaignMediaUrl = (id: string, filename: string): string => {
  return `${assetUrl}/campaign/${id}/${filename}`;
};

export const uploadMedia = async (
  url: string,
  file: FileObject,
  progressCallback: (p: number) => void,
): Promise<void> => {
  await axios({
    method: 'PUT',
    url: url,
    data: dataURLtoFile(file.file, file.filename),
    headers: {
      'Content-Type': file.format,
    },
    onUploadProgress: (event) => {
      const progress = ((event.loaded / event.total) * 100).toFixed(0);
      progressCallback(parseFloat(progress));
    },
  });
};

export const prepareMediaRequest = (data: ChannelMediaStructure): CampaignMediaResponse[] => {
  const list: CampaignMediaResponse[] = [];
  const mediaList = flatten(Object.values(data));
  mediaList.forEach((item) => {
    if (item.media.file) {
      const obj: CampaignMediaResponse = {
        ...(item.id && { id: item.id }),
        channel: item.channel,
        media: prepareMediaName(item),
        mediaFormat: item.media.format,
        isDefault: item.isDefault,
        ratio: item.ratio,
      };
      list.push(obj);
    }
  });
  return list;
};

export const prepareTemplateRequest = (data: ChannelTemplateStructure): CampaignTemplateResponse[] => {
  const list: CampaignTemplateResponse[] = [];
  const templateList = flatten(Object.values(data));
  templateList.forEach((item) => {
    const obj: CampaignTemplateResponse = {
      ...(item.id && { id: item.id }),
      channel: item.channel,
      post: item.channel === 'Twitter' ? item.post.replace('@', '#') : item.post,
    };
    list.push(obj);
  });
  return list;
};

export const prepareChannelMediaFromResponse = (
  initData: ChannelMediaStructure,
  id: string,
  list: CampaignMediaResponse[],
): ChannelMediaStructure => {
  if (list) {
    list.forEach((item) => {
      if (item.media) {
        const channel = item.channel.toUpperCase() as SOCIAL_CHANNEL;
        const obj: ChannelMediaObject = {
          ...(item.id && { id: item.id }),
          isDefault: item.isDefault,
          channel,
          ratio: item.ratio,
          media: { file: generateCampaignMediaUrl(id, item.media), filename: item.media, format: item.mediaFormat },
        };
        const mediaList = [...initData[channel]];
        mediaList.push(obj);
        initData[channel] = mediaList;
      }
    });
  }
  return initData;
};

export const prepareChannelTemplatesFromResponse = (
  initData: ChannelTemplateStructure,
  list: CampaignTemplateResponse[],
): ChannelTemplateStructure => {
  if (list) {
    list.forEach((item) => {
      const channel = item.channel.toUpperCase() as SOCIAL_CHANNEL;
      const obj: ChannelTemplateObject = {
        ...(item.id && { id: item.id }),
        channel,
        post: item.post,
      };
      let templateList = [...initData[channel]];
      templateList.push(obj);
      templateList = templateList.filter((item) => item.id);
      initData[channel] = templateList;
    });
  }
  return initData;
};

export const chartColors = ['#e4485c', '#1d40ad', '#f80aec', '#f8e80a', '#026b14'];

export const prepareMediaName = (item: ChannelMediaObject): string =>
  item.id ? item.media.filename : `${item.channel}-${item.ratio}-${item.media.filename}`;

export const calculateTTCMInfluence = (pointValues: PointValues, metrics: Metrics): number => {
  let totalScore = 0;
  Object.keys(pointValues).forEach((key) => {
    const point = pointValues[key];
    totalScore += point * (metrics[key] || 0);
  });
  return totalScore;
};

export const formatCurrency = (amount: number): string => {
  const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return USDollar.format(amount);
};

export const formatNumber = (amount: number): string => {
  const formatter = new Intl.NumberFormat('en-US');
  return formatter.format(amount);
};

export const formatDate = (date: string | Date | number): string => {
  return format(new Date(date), 'MMM dd YYY');
};
