import React, { useEffect, useState } from 'react';
import { WithdrawalRequest } from '../../types';
import { CircularProgress } from '@material-ui/core';
import './styles.scss';
import CustomButton from '../CustomButton';
import { useDispatch } from 'react-redux';
import { showErrorAlert } from '../../store/actions/alerts';
import Pagination from '../Pagination/Pagination';
import { ApiClient } from '../../services/apiClient';
import { formatDate, formatNumber } from '../../helpers/utils';
import { APPROVAL_STATUS } from '../../helpers/constants';

export const WithdrawalRequests: React.FC = () => {
  const dispatch = useDispatch();
  const [withdrawals, setWithdrawals] = useState<WithdrawalRequest[]>([]);
  const [loading, setLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [status, setStatus] = useState<APPROVAL_STATUS>();
  const [refetch, setRefetch] = useState(new Date().getTime());
  const [skip, setSkip] = useState(0);
  const [take] = useState(20);
  const [total, setTotal] = useState(0);
  const [selectedWithdrawal, setSelectedWithdrawal] = useState<string>('');

  useEffect(() => {
    setLoading(true);
    ApiClient.getWithdrawalRequests({ skip, take })
      .then((response) => {
        setWithdrawals(response.items);
        setTotal(response.total);
      })
      .catch((error) => dispatch(showErrorAlert((error as Error).message)))
      .finally(() => setLoading(false));
  }, [refetch]);

  const updateWithdrawalStatus = (status: APPROVAL_STATUS, transferId: string) => {
    setStatusLoading(true);
    setStatus(status);
    ApiClient.updateWithdrawalRequests({ status, transferId })
      .then(() => {
        setRefetch(new Date().getTime());
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setStatusLoading(false);
        setStatus(undefined);
        setSelectedWithdrawal('');
      });
  };

  const getValue = (skip: number) => {
    setSkip(skip);
  };

  if (loading) {
    return (
      <div className="loading">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="withdrawalRequestContainer">
      {withdrawals?.length <= 0 ? (
        <div>No withdrawal requests</div>
      ) : (
        <div className="tableWrapper">
          <table>
            <thead>
              <tr className="tableRow">
                <th>Username</th>
                <th>Symbol</th>
                <th>Status</th>
                <th>Action</th>
                <th>Amount</th>
                <th>CreatedAt</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {withdrawals.map((item) => (
                <tr key={item.transferId} className="hover:bg-gray-100 border-b-2 border-solid border-gray-100">
                  <td className="px-7 py-5 text-left capitalize">{item.username}</td>
                  <td className="px-7 py-5 text-left">{item.symbol}</td>
                  <td className="px-7 py-5 text-left">{item.status}</td>
                  <td className="px-7 py-5 text-left">{item.action}</td>
                  <td className="px-7 py-5 text-left">{formatNumber(item.amount)}</td>
                  <td className="px-7 py-5 text-left">{formatDate(item.createdAt)}</td>
                  <td className="py-5 text-right">
                    <CustomButton
                      className="approve-btn"
                      loading={
                        status === APPROVAL_STATUS.APPROVED && selectedWithdrawal === item.transferId && statusLoading
                      }
                      onClick={() => {
                        setSelectedWithdrawal(item.transferId);
                        updateWithdrawalStatus(APPROVAL_STATUS.APPROVED, item.transferId);
                      }}
                    >
                      Approve
                    </CustomButton>
                  </td>
                  <td className="py-5 text-right">
                    <CustomButton
                      className="reject-btn"
                      loading={
                        status === APPROVAL_STATUS.REJECTED && selectedWithdrawal === item.transferId && statusLoading
                      }
                      onClick={() => {
                        setSelectedWithdrawal(item.transferId);
                        updateWithdrawalStatus(APPROVAL_STATUS.REJECTED, item.transferId);
                      }}
                    >
                      Reject
                    </CustomButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {total > take && <Pagination skip={skip} take={take} total={total} getValue={getValue} />}
    </div>
  );
};
