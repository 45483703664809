import React, { FC } from 'react';
import './styles.scss';
import { TTCMActionsProps } from './Content';
import Actions from '../../../components/NewCampaign/Actions';
import useStoreTTCMCampaignSelector from '../../../hooks/useStoreTTCMCampaignSelector';
import { useDispatch } from 'react-redux';
import { addTTCMCampaign } from '../../../store/actions/ttcmCampaignList';
import { useHistory } from 'react-router';
import useStoreTTCMCampaignListSelector from '../../../hooks/useStoreTTCMCampaignListSelector';
import { showErrorAlert } from '../../../store/actions/alerts';
import { formatDate } from '../../../helpers/utils';

const Preview: FC<TTCMActionsProps> = ({ activeStep, firstStep, finalStep, handleBack, handleNext }) => {
  const campaign = useStoreTTCMCampaignSelector();
  const campaignList = useStoreTTCMCampaignListSelector();
  const dispatch = useDispatch();
  const { push } = useHistory();

  const finalize = () => {
    if (!campaignList.find((item) => item.name === campaign.name)) {
      dispatch(addTTCMCampaign(campaign));
      push('/campaigns');
    } else {
      dispatch(showErrorAlert('A campaign already exists with this name'));
    }
  };

  return (
    <div className="previewContainer">
      <h1 className="title">Verify Campaign Information</h1>

      <div className="campaignData">
        <div className="box">
          <h3 className="boxTitle">Title</h3>
          <div className="boxContent">{campaign.name}</div>
        </div>

        <div className="box">
          <h3 className="boxTitle">Duration</h3>
          <div className="boxContent">{`${formatDate(campaign.beginDate)} -- ${formatDate(campaign.endDate)}`}</div>
        </div>

        <div className="box">
          <h3 className="boxTitle">Budget</h3>
          <div className="boxContent">
            {campaign.budget} {campaign.currency.split('-')[0]}
          </div>
        </div>

        <div className="box">
          <h3 className="boxTitle">Tagline</h3>
          <div className="boxContent">{campaign.tagline}</div>
        </div>

        <div className="box">
          <h3 className="boxTitle">Description</h3>
          <div className="boxContent">{campaign.description}</div>
        </div>

        <div className="box">
          <h3 className="boxTitle">Instructions</h3>
          <div className="boxContent">{campaign.instructions}</div>
        </div>

        <div className="box">
          <h3 className="boxTitle">Keywords</h3>
          <div className="boxContent">
            {campaign.keywords.map((item) => (
              <span key={item} className="pill">
                {item}
              </span>
            ))}
          </div>
        </div>

        <div className="box">
          <h3 className="boxTitle">Tags</h3>
          <div className="boxContent">
            {campaign.tags.map((item) => (
              <span key={item} className="pill">
                {item}
              </span>
            ))}
          </div>
        </div>

        <div className="box">
          <h3 className="boxTitle">Target</h3>
          <div className="boxContent">{campaign.target}</div>
        </div>

        <div className="box">
          <h3 className="boxTitle">Target Video</h3>
          <div className="boxContent">{campaign.targetVideo}</div>
        </div>
      </div>

      <Actions
        activeStep={activeStep}
        firstStep={firstStep}
        finalStep={finalStep}
        handleBack={handleBack}
        handleNext={handleNext}
        handleSubmit={finalize}
      />
    </div>
  );
};

export default Preview;
